import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "./ToggleButton.css";
import { useTranslation } from "react-i18next";
import {
  useGetAllCommune
} from "../../api/caller";

const isEmpty = (value) => value === "" || value == null || value === undefined;

const getMarkerColor = (ecoData, selectedPermis) => {
  console.log("ecoData++",selectedPermis)
  const specificKeys = [
    "TOTAL_AN",
    "TOTAL_AP",
    "TOTAL_ODPRT",
    "TOTAL_ANR 1 / ANR 2",
    "TOTAL_SD",
    "Total_PC",
    "Total_PH",
    "TOTAL_REC",
    "TOTAL_RFC",
    "TOTAL_DML",
  ];


  const keysToCheck = selectedPermis.length > 0 ? selectedPermis : specificKeys;

  const validKeys = keysToCheck.filter((key) => specificKeys.includes(key));


  const values = validKeys.map((key) => ecoData[key]);
  if(values[0] === undefined){
    console.log("ecoDataaaa",ecoData)
    return 'grey';
  }
  const isEmptyOrZero = (value) => value === undefined || value === null || Number(value) === 0 || value === "";

  const allEmptyOrZero = values.every(isEmptyOrZero);
  const allValid = values.every((value) => !isEmptyOrZero(value));
  const someValid = values.some((value) => !isEmptyOrZero(value));

  if (allEmptyOrZero) {
    return "grey";
  } else if (allValid) {
    return "green";
  } else if (someValid) {
    return "orange";
  }
};


const shouldDisplayMarker = (color, options) => {
  if (color === "grey" && options.includes("Non couverte")) return true;
  if (color === "green" && options.includes("Couverte")) return true;
  if (color === "orange" && options.includes("Partiellement couverte"))
    return true;
  return false;
};

const createCustomIcon = (color) => {
  const svgIcon = `
     <svg width="34" height="38" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 23.8711 0.576173 26.6076 1.6192 29.1003C5.94533 40.912 21.1104 49 21.1104 49C21.1104 49 39.7389 39.0648 41.5928 25.1361C41.8599 23.7989 42 22.4158 42 21Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6509 24.7849L38.6318 24.8814L38.619 24.9789C37.8426 30.8763 33.4427 36.2795 28.6948 40.4277C26.3812 42.4489 24.1126 44.0616 22.42 45.1691C21.9283 45.4908 21.4872 45.7686 21.1104 46C20.8599 45.8462 20.5811 45.672 20.2779 45.4779C18.8615 44.5714 16.9286 43.2433 14.8719 41.5641C10.6804 38.142 6.31788 33.5375 4.4362 28.3436L4.41287 28.2792L4.38668 28.2159C3.4944 26.0601 3 23.691 3 21.1975C3 11.1473 11.0589 3 21 3C30.9411 3 39 11.1473 39 21.1975C39 22.4283 38.8796 23.6276 38.6509 24.7849Z" fill="${color}"/>
            <path d="M29 21C29 16.5817 25.4183 13 21 13C16.5817 13 13 16.5817 13 21C13 25.4183 16.5817 29 21 29C25.4183 29 29 25.4183 29 21Z" fill="white"/>
          </svg>
    
  `;

  return L.divIcon({
    className: "custom-marker",
    html: svgIcon,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });
};

const MarkersLayer = ({
  eco,
  commune,
  options = [],
  communeSearch,
  selectedPermis = [],
  allcommunes,
  lang
}) => {
  const { t: tNR, i18n } = useTranslation("couverture_geo");
  const map = useMap();
  const markersRef = React.useRef(
    L.markerClusterGroup({
      maxClusterRadius: 50,
      disableClusteringAtZoom: 15,
      spiderfyOnMaxZoom: true,
      chunkedLoading: true,
    })
  );
  function findLabelByValue(dataArray, searchValue) {
    const result = dataArray?.find(item => item.value === searchValue);
    return result ? result.label : "";
  }

  const communesAR=allcommunes?.data?.page;
  console.log("allcommunes+222",communesAR,lang)
  const [inquiryCriteria, setInquiryCriteria] = useState({
    page: 1,
    size: 8,
    search: "",
    commune: "",
    province:"",
    reference: "",
});
  const getAllCommune=useGetAllCommune(inquiryCriteria.province,"ar");

  useEffect(() => {
    markersRef.current.clearLayers();

    if (!eco || !eco.data || eco.data.length === 0) {
      console.log("No eco data available.");
      return;
    }

    let targetMarker = null;

    eco.data.forEach((ecoData) => {
      const latitude = Number(ecoData.LATITUDE);
      const longitude = Number(ecoData.LONGITUDE);
      const communeEco = ecoData.ARRONDISSEMENT_FR;

      if (
        isNaN(latitude) ||
        isNaN(longitude) ||
        (latitude === 0 && longitude === 0)
        || communeEco=="COMMUNE DE SALE"
        || communeEco=="COMMUNE OULED GHADBANE"
        || communeEco=="COMMUNE DE RABAT"
        || communeEco=="COMMUNE DE FES"
        || communeEco=="COMMUNE DE SALE"
        || communeEco=="COMMUNE DE MARRAKECH"
        || communeEco=="COMMUNE DE TANGER"
        || communeEco=="COMMUNE CASABLANCA"
      ) {
        console.log("Invalid coordinates for ecoData:", ecoData);
        return;
      }

      if (commune && commune !== communeEco) return;
      if (
        communeSearch &&
        !communeEco.toLowerCase().includes(communeSearch.toLowerCase())
      )
        return;
      const markerColor = getMarkerColor(ecoData, selectedPermis);

      const shouldDisplay =
        selectedPermis.length === 0 ||
        selectedPermis.some((permis) => ecoData[permis] > 0);
      if (!shouldDisplayMarker(markerColor, options)) return;
      const customIcon = createCustomIcon(markerColor);
      const marker = L.marker([latitude, longitude], { icon: customIcon });

      if (commune && commune === communeEco) {
        targetMarker = marker;
      }
      console.log("lang+++",lang)

      const popupContent = `
        <div style="text-align: center;">
          <h3 style="margin: 0;font-family:${lang?'Tajawal':'inter'}">${lang ? (findLabelByValue(communesAR, ecoData.ARRONDISSEMENT_FR) || ecoData.ARRONDISSEMENT_FR) : ecoData.ARRONDISSEMENT_FR}</h3>
          <div style="
    z-index: 10;
    position: fixed;
">           <button 
              id="urbanism-btn-${ecoData.ID_LIEU}" 
              onclick="handleUrbanismClick('${ecoData.ID_LIEU}')" 
              style="border: 1px solid #F0F0F0; padding: 10px 20px; margin: 5px; background: white; cursor: pointer; font-size: 16px;width: 110px;font-family:Tajawal;
"
              onmouseover="this.style.background='#F0F0F0';" 
              onmouseout="this.style.background='white';"
            >
              ${tNR("Urbanism")}
            </button>
            <button 
              id="economie-btn-${ecoData.ID_LIEU}" 
              onclick="handleEconomieClick('${ecoData.ID_LIEU}')" 
              style="border: 1px solid #F0F0F0; padding: 10px 20px; margin: 5px; background: white; cursor: pointer; font-size: 16px; border-bottom: none; color:#1890FF;width: 110px;font-family:Tajawal;"
              onmouseover="this.style.background='#F0F0F0';" 
              onmouseout="this.style.background='white';"
            >
              ${tNR("Economie")}
            </button>
          </div>
          <div id="info-${ecoData.ID_LIEU}" style="
    border-top: 1px solid #F0F0F0;
    margin-top: 18.8%;
"></div>
        </div>
      `;

      marker.bindPopup(popupContent,{
        autoPan: true, 
        autoPanPaddingTopLeft: [50, 150], 
        autoPanPaddingBottomRight: [50, 50] 
    });
      marker.on("mouseover", function () {
        this.openPopup(); 
      });

      marker.on("mouseover", () => {
        handleUrbanismClick(ecoData.ID_LIEU);
      });
      markersRef.current.addLayer(marker);
    });

    map.addLayer(markersRef.current);

    if (targetMarker) {
      map.setView(targetMarker.getLatLng(), 15, { animate: true });
      // handleUrbanismClick(ecoData.ID_LIEU);
      // targetMarker.openPopup();
      
    }

    return () => {
      map.removeLayer(markersRef.current);
    };
  }, [eco, commune, options, map, communeSearch, selectedPermis]);

  const [isRtl, setIsRtl] = useState(false);

  useEffect(() => {
    setIsRtl(i18n.dir() === "rtl");
    i18n.on("languageChanged", (lang) => {
      setIsRtl(i18n.dir(lang) === "rtl");
    });
  }, [i18n]);

  const permisMapping = {
    Total_PC: tNR("PC"),
    Total_PH: tNR("PH"),
    TOTAL_REC: tNR("REC"),
    TOTAL_RFC: tNR("REF"),
    TOTAL_DML: tNR("DIM"),
  };
  
  window.handleUrbanismClick = (id) => {
    const infoDiv = document.getElementById(`info-${id}`);
    const ecoData = eco.data.find((data) => data.ID_LIEU === id);

    const isCovered = (value) =>
      value !== 0 && value !== null && value !== "" && value !== undefined;

    const totals = {
      Total_PC: isCovered(ecoData.Total_PC) ? "Couverte" : "Non couverte",
      Total_PH: isCovered(ecoData.Total_PH) ? "Couverte" : "Non couverte",
      TOTAL_REC: isCovered(ecoData.TOTAL_REC) ? "Couverte" : "Non couverte",
      TOTAL_RFC: isCovered(ecoData.TOTAL_RFC) ? "Couverte" : "Non couverte",
      TOTAL_DML: isCovered(ecoData.TOTAL_DML) ? "Couverte" : "Non couverte",
    };

    const getStatusColor = (status) =>
      status === "Couverte" ? "green" : "grey";
    let displayTotals = [];

    if (selectedPermis.length === 0) {
      displayTotals = Object.entries(totals);
    } else {
      displayTotals = Object.entries(totals).filter(([key]) =>
        selectedPermis.includes(key)
      );
    }

    infoDiv.innerHTML = `
      <div dir="${isRtl ? "rtl" : "ltr"}" style="margin-top: 13px;">
        ${displayTotals
          .map(
            ([key, value]) => `
          <div class="ECOInfos6Y">
            <span class="EcoStatusY8">${permisMapping[key]}:</span>
            <span class="EcoStatusY5" style="color: ${getStatusColor(
              value
            )};">${tNR(value)}</span>
          </div>
        `
          )
          .join("")}
      </div>
    `;

    document.getElementById(`economie-btn-${id}`).style.borderBottom =
      "1px solid #F0F0F0";
    document.getElementById(`economie-btn-${id}`).style.color = "black";

    const economieBtn = document.getElementById(`urbanism-btn-${id}`);
    economieBtn.style.borderBottom = "none";
    economieBtn.style.color = "#1890FF";
  };

  const economieMapping = {
    TOTAL_AN:tNR("AN"),
    TOTAL_AP:  tNR("AP"),
    TOTAL_ODPRT:tNR("ODPRT"),
    "TOTAL_ANR 1 / ANR 2":tNR("ANR1_2"),
    TOTAL_SD: tNR("SD"),
  };

  window.handleEconomieClick = (id) => {
    console.log("select++=s", selectedPermis);
    const infoDiv = document.getElementById(`info-${id}`);
    const ecoData = eco.data.find((data) => data.ID_LIEU === id);

    const isCovered = (value) => value !== 0 && value !== null && value !== "";

    const totals = {
      TOTAL_AN: isCovered(ecoData.TOTAL_AN) ? "Couverte" : "Non couverte",
      TOTAL_AP: isCovered(ecoData.TOTAL_AP) ? "Couverte" : "Non couverte",
      TOTAL_ODPRT: isCovered(ecoData.TOTAL_ODPRT) ? "Couverte" : "Non couverte",
      "TOTAL_ANR 1 / ANR 2": isCovered(ecoData["TOTAL_ANR 1 / ANR 2"])
        ? "Couverte"
        : "Non couverte",
      TOTAL_SD: isCovered(ecoData.TOTAL_SD) ? "Couverte" : "Non couverte",
    };

    const getStatusColor = (status) =>
      status === "Couverte" ? "green" : "grey";

    let displayTotals = [];
    if (selectedPermis.length === 0) {
      displayTotals = Object.entries(totals);
    } else {
      displayTotals = Object.entries(totals).filter(([key]) =>
        selectedPermis.includes(key)
      );
    }

    infoDiv.innerHTML = `
      <div dir="${isRtl ? "rtl" : "ltr"}" style="margin-top: 13px;">
      ${displayTotals
        .map(
          ([key, value]) => `
          <div class="ECOInfos6Y">
            <span class="EcoStatusY8">${economieMapping[key] || key}:</span>
            <span class="EcoStatusY5" style="color: ${getStatusColor(value)};">${tNR(value)}</span>
          </div>
        `
        )
        .join("")}
    </div>
  `;

    // Style the buttons
    document.getElementById(`urbanism-btn-${id}`).style.borderBottom =
      "1px solid #F0F0F0";
    document.getElementById(`urbanism-btn-${id}`).style.color = "black";

    const economieBtn = document.getElementById(`economie-btn-${id}`);
    economieBtn.style.borderBottom = "none";
    economieBtn.style.color = "#1890FF";
  };

  return null;
};

export default MarkersLayer;
