import Icon from '../util/Icon';
import React, {useState} from "react";
import styles from "./filter.module.css"
import classNames from "classnames";
export const FilterButton=(props)=>{
const [isOpen,setStatus]=useState(false);
const filterButtonBackgroundStyles=classNames({
    [styles.activeFilterButton]:isOpen,
    [styles.nonActiveFilterButton]:!isOpen
})
 const onClick=()=>{
    props.onChange(!isOpen);
    setStatus(!isOpen);
}

return (<button className={`button-none-button transition-all !duration-600  ${styles.filterButton} ${filterButtonBackgroundStyles}`}  onClick={onClick}>
        <Icon iconType={"light"} iconName={"filter-list"} />
        </button>
)

}