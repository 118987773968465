import React from 'react';
import './ToggleButton.css';
import { useTranslation } from "react-i18next";

const ToggleButton = ({ selectedOptions, onToggle }) => {
  const { t: tNR } = useTranslation("couverture_geo");

  // Define the original labels and their corresponding translations
  const labels = [
    { original: 'Couverte', display: tNR('Couverte') },
    { original: 'Partiellement couverte', display: tNR('Partiellement couverte') },
    { original: 'Non couverte', display: tNR('Non couverte') }
  ];
  
  const handleClick = (label) => {
    const newSelected = selectedOptions.includes(label)
      ? selectedOptions.filter((item) => item !== label)
      : [...selectedOptions, label];
    onToggle(newSelected);
  };

  return (
    <div className="toggle-button-container">
      {labels.map(({ original, display }) => (
        <button
          key={original}
          onClick={() => handleClick(original)}
          className={`toggle-button ${
            selectedOptions.includes(original)
              ? `selected-${original.toLowerCase().replace(/\s+/g, '-')}`
              : ''
          } ${original === 'Partiellement couverte' ? 'partiellement-large' : ''}`}
        >
          {display}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
