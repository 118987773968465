import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import React, {createContext, useContext, useEffect, useRef, useState,useReducer} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import useHandleTranslation from "../../hooks/useHandleTranslation";
import {scrollToTopOnMount, toLogin} from "../../util/functionsUtil";
import styles from "./proposition.module.css";
import InputForm from "../../components/input/InputForm";
import { useForm } from "react-hook-form";
import sty from "../refJuri/jurid.module.css";
import {yupResolver} from "@hookform/resolvers/yup";



import {
    useGetActivityDetail,
    useGetAllCommune,
    useGetAllProvince, useGetAutoCompleteActivity,
    useGetRefActivitiesByCriteria,useGetActivityNatures,
    useAddNewProposation
} from "../../api/caller";
import {isEmptyString} from "../../util/functionsUtil";
import { Select } from "../../components/select/Select";
import SelectForm from "../../components/select/SelectForm";
import {PROPOSITION_ACTIVITE_SCHEMA } from "../../holders/schemas";
import TextAreaForm from "../../components/input/TextAreaForm";
import useHandleClassName from "../../hooks/useHandleClassName";
import Btn from "../../components/button/Btn";
import Modal from "../../components/modal/Modal";






const PropositionActPage=()=>{
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const navigate=useNavigate();


    const [activityCard,setActivityCard]=useState(null);
    const [activityCode,setActivityCode]=useState(null);
    const [wrappedPage,setWrappedPage]=useState(false);
    const {t:tBC}=useTranslation("breadcrumb");
    const {t:tRA}=useTranslation("propAct");
    const {getDirX}=useHandleTranslation()
    const searchPageBreadcrumbParts = [
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("prepare_dossier"), link: '/'},
        {text: tBC("proposition_nouvelle_activite"), link: '/propositionActivite'},
    ];
    const detailPageBreadcrumbPartsDetail = [
        ...searchPageBreadcrumbParts,
    ];
    const breadcrumbParts=!wrappedPage ? searchPageBreadcrumbParts:detailPageBreadcrumbPartsDetail

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };
   

    return(
        <div id={"page"} style={{minHeight: "100vh"}}>
        <div className={`${styles.breadcrumb +" breadcrumb"}`}>
            <BreadCrumb parts={breadcrumbParts}/>
        </div>
        <div  className={`${sty.pageTitleContainer} ${styles.responsiveWeb}`}>
            <div className={sty.pageTitle}>{tRA("page_title_1")}</div>
            <div className={`${sty.pageTitleDescription+' whitespace-pre-wrap'} pageTitleDescription-mobile`}>{tRA("title_description")}</div>
        </div>
        <div className={`${sty.pageTitleContainerMobile} ${styles.responsiveMobile}`}>
            <div className={`${sty.pageTitleMobile} ${styles.pageTitleMobileContainer}`}>
                <div className="cursor-pointer pt-2">
                    {wrappedPage && <i onClick={() => setWrappedPage(false)} className={`fa-solid fa-${getDirX()}-long`}></i>}
                    {!wrappedPage && <i onClick={() => navigate(-1)} className={`fa-solid fa-${getDirX()}-long`}></i>}
                </div>
                <span className={`${sty.pageTitleMobileItem} ${styles.pageTitleItemMobile}`}>{tRA("page_title_1")}</span>
            </div>
            <div className={`${sty.pageTitleDescription+' whitespace-pre-wrap'} ${styles.pageTitleDescriptionMobile}`} >{tRA("title_description")}</div>
        </div>
        <div  className={sty.pageTitleContainer}>
            <div className={`${sty.pageTitle} ${styles.pageTitleContainerMobile}`}>{tRA("page_title_2")}</div>
        </div>
        <PropositionActForm/>

    </div>
        
    )
}

const PropositionActForm = () => {
    const {currLng} = useHandleTranslation();
    const getAllProvince = useGetAllProvince(true, currLng);
    const [localite,setLocalite]=useState({province:"",communeCode:""});
    const getAllCommune=useGetAllCommune(localite.province,currLng,"prop");
    const {t:tRA}=useTranslation("propAct");
    const [communeOptions, setCommuneOptions] = useState([]);
    const {translate}=useHandleClassName();

    const [prefecture, setPrefecture] = useState(null);
    const [codePref, setCodePref] = useState(null);
    const [codeComm, setCodeComm] = useState(null);
    const [descriptionCom, setDescriptionCom] = useState(null);
    const { mutate: addNewProposation, isLoading, isError, customHandler } = useAddNewProposation(); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isSuccess) {
            navigate('/'); 
        }
    };



    useEffect(() => {
        if (localite.province) {
            setCommuneOptions(getAllCommune.data?.page ?? []);
        } else {
            setCommuneOptions([]);
        }
    }, [localite.province, getAllCommune.data]);
    const handleProvinceChange = (selectedProvince) => {
        console.log("selectedProvince",selectedProvince)
        const selectedprovince = getAllProvince.data?.page.find(p => p.value === selectedProvince)?.code;
        setPrefecture(selectedprovince);
        const codePref = getAllProvince.data?.page.find(p => p.value === selectedProvince)?.codePref;
        setCodePref(codePref);
        setLocalite({ ...localite, province: selectedProvince });
    };
    

    const handlecommuneChange = (selectedCommune) => {
        const codeCom = getAllCommune.data?.page.find(p => p.value === selectedCommune)?.codeComm;
        setCodeComm(codeCom);
        const descpCom = getAllCommune.data?.page.find(p => p.value === selectedCommune)?.label;
        setDescriptionCom(descpCom);
    };
    

    const { register,setValue,formState: { errors }, handleSubmit }= useForm({
        resolver: yupResolver(PROPOSITION_ACTIVITE_SCHEMA ),
    });

    const onCommuneSelectClick= (isOpen)=>{
        console.log("cisOpenom",isOpen)
        if(!isOpen && !isEmptyString(localite.province)) getAllCommune.refetch();
        
   
    }
    const onSubmit = (data) => {
      console.log("dataaaaaaaaaa ",data)
        const formData = {
            ...data,
            prefecture,
            codeComm,
            codePref,
            descriptionCom
        };

        addNewProposation(formData, {
            onSuccess: () => {
                setIsSuccess(true);
                setModalMessage(tRA("MESSAGE_SUCESS_BODY")); 
                setModalTitle(tRA("MESSAGE_SUCESS_PROPO")) 
                setIsModalOpen(true);  
            },
            onError: (error) => {
                setIsSuccess(false);
                const errorMessage = error?.response?.data || "An error occurred.";
                //setModalMessage(); 
                setModalTitle(tRA("FAILED_ADD_PROP"))
                setIsModalOpen(true);  
            },
        });
        console.log("Form submission:", formData);
   
    };
    console.log("errors ",errors)
  
    return (
      <div className= {styles.boxborder}  >
        <div className={styles.container}>
       
            <div >
              <div>
                <p className={`${translate("bold")}  text-base `+styles.headerPropAct}>
                  {tRA("prop_act_title")}
                </p>
                <div className={styles.lineProp } ></div>
              </div>
              <div className={styles.gridContainer}>
                <div className={styles.gridContainer}>
                  <InputForm
                    register={register}
                    errors={errors}
                    name={"nom"}
                    label={tRA("prop_act_input1_label")}
                    placeholder={tRA("prop_act_input_placeholder")}
                    important
                  />
                  <InputForm
                    register={register}
                    errors={errors}
                    name={"prenom"}
                    label={tRA("prop_act_input2_label")}
                    placeholder={tRA("prop_act_input_placeholder")}
                    important
                  />
                </div>

                <InputForm
                  register={register}
                  errors={errors}
                  name={"email"}
                  label={tRA("prop_act_input3_label")}
                  placeholder={tRA("prop_act_input_placeholder")}
                  important
                />
              </div>
            </div>
            <div className={styles.gridContainerSelect}>
              <SelectForm
                label={tRA("prop_act_select1_label")}
                placeholder={tRA("prop_act_select_placeholder")}
                options={getAllProvince.data?.page}
                loading={getAllProvince.isFetching}
                setter={setValue}
                errors={errors}
                name={"province"}
                onChange={handleProvinceChange}
                search
                important
                disabled={!localite.province}
              />
              <SelectForm
                label={tRA("prop_act_select1_label")}
                placeholder={tRA("prop_act_select_placeholder")}
                options={getAllCommune.data?.page}
                loading={getAllCommune.isFetching}
                setter={setValue}
                onClick={onCommuneSelectClick}
                onChange={handlecommuneChange}
                errors={errors}
                name={"commune"}
                search
                important
              />
            </div>
            <div>
              <p className={`${translate("bold")}  text-base `+styles.headerPropAct}>
                {tRA("prop_act_title1")}
              </p>
              <div className={styles.lineProp}></div>
            </div>
            <div className={styles.gridContainer}>
              <InputForm
                register={register}
                errors={errors}
                name={"intitule"}
                label={tRA("prop_act_input4_label")}
                placeholder={tRA("prop_act_input_placeholder")}
                important
              />
              </div>
              <div>
              <TextAreaForm
                register={register}
                errors={errors}
                name={"description"}
                label={tRA("prop_act_input5_label")}
                placeholder={tRA("prop_act_input_placeholder")}
                important
              />
            </div>
            <div className="flex justify-end">
              <div className={styles.btnWith}>
                <Btn
                  backgroundColor={"#0369A1"}
                  className="flex justify-center items-center"
                  paddingX="25"
                  paddingY="10"
                  color="#fff"
                  content={tRA("envoyer")}
                  onClick={handleSubmit(onSubmit)} 
                >
                  <i class="fa-thin fa-paper-plane mx-2"></i>
                </Btn>
              </div>
            </div>

            <Modal isOpen={isModalOpen}>
                <>
                    <div className="ed-modal-title">{modalTitle}</div>
                    <div className="ed-modal-message">{modalMessage}</div>
                    <button  className={`${styles.btnProp} ${translate("regular")} ` } onClick={handleCloseModal}>
                        {tRA("MESSAGE_RETOUR")}
                    </button>
                </>
            </Modal>
        
        </div>
      </div>
    );
};

export default PropositionActPage;