import React, { useCallback, useEffect, useRef, useState,useMemo  } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import globalStyles from "../globalstyles.module.css";
import { FadeLoader } from "react-spinners";
import "leaflet/dist/leaflet.css";
import "./Style.module.css";
import "./popupstyling.css";
import { DatePicker, Space } from "antd";
import styles from "./Style.module.css";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import classNames from "classnames";
import { Select } from "../../components/select/Select";
import GlobalSearch from "../../components/search/GlobalSearch";
import { FilterButton } from "../../components/filters/FilterButton";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import arEG from "antd/es/date-picker/locale/ar_EG";
import regionpub from "./regionsmajd.json";
import marocpolygn from "../../asset/geojson/ma.json";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {
  useGetAllCommune,
  useGetAllProvince
} from "../../api/caller";
import { booleanPointInPolygon } from "@turf/turf";

const StatMapv2 = () => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const mapRef = useRef(null);
  const markerClusterGroupRef = useRef(L.markerClusterGroup());
  const { isLngAr, isLngFr ,currLng} = useHandleTranstation();
  const labelClass = isLngAr
    ? `${styles.customlabel} ${styles.rtl}`
    : `${styles.customlabel} ${styles.ltr}`;
  const locale = isLngAr ? arEG : "";
  const [isLoading, setIsLoading] = useState(true);
  const [loadlang,setloadlang] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [searchCle, setSearchCle] = useState(searchCriteria);
  const searchCleRef = useRef(searchCriteria);
  const [dates, setDates] = useState(["", ""]);
  const [datesAut, setDatesAut] = useState(["", ""]);
  const abortControllerRef = useRef();
  const { RangePicker } = DatePicker;
  let geoJsonLayerregion = null;
  const datedeptominRef = useRef(dates[0]);
  const datedepotmaxRef = useRef(dates[1]);
  const [userMarker, setUserMarker] = useState(null);
  const markersRef = useRef([]);
  const dateauthominRef = useRef(datesAut[0]);
  const dateauthmaxRef = useRef(datesAut[1]);

  const [inquiryCriteria, setInquiryCriteria] = useState({
    page: 1,
    size: 8,
    search: "",
    commune: "",
    province:"",
    reference: "",
});



const useDataHandler = (inquiryCriteria, currLng, isLngAr) => {
  const [loadlang, setLoadLang] = useState(false);
  
  const getAllProvince = useGetAllProvince(true, isLngAr ? "ar" : "fr");

  const provinceData = useMemo(() => {
    if (getAllProvince?.status === "success") {
      return getAllProvince?.data?.page || [];
    }
    return [];
  }, [getAllProvince?.status, getAllProvince?.data?.page]);

  // Memoizing the commune data
  const { status, data, refetch } = useGetAllCommune(inquiryCriteria.province, currLng, '', true);
  const communedataAr = useMemo(() => {
    if (status === "success" && Array.isArray(data?.page)) {
      return data.page;
    }
    return [];
  }, [status, data]);


  const handleSearch = useCallback((searchValue, type = 'province') => {
    let result = null;

    if (type === 'province') {
      result = provinceData.find(item => item?.value === searchValue);
    } else if (type === 'commune') {
      result = communedataAr.find(item => item?.value === searchValue);
      setLoadLang(!loadlang);
    }
    return result ? result.label : "";
  }, [provinceData, communedataAr, loadlang]);

  useEffect(() => {
    if (status !== "success") {
      refetch();
    }
  }, [refetch, status, loadlang]);
  return { handleSearch, provinceData, communedataAr };
};



const { handleSearch, provinceData, communedataAr } = useDataHandler(inquiryCriteria, currLng, isLngAr);

  const switcher = useRef(false);
  const { t: tBC } = useTranslation("breadcrumb");
  const newmap = useRef(null);
  const [displaydata,setdisplaydata]=useState(true);
  const [displaydatacluster,setdisplaydatacluster]=useState(true);
  const fetchData = useCallback(
    async (
      bounds,
      cle,
      datedepotmin,
      datedepotmax,
      dateauthmin,
      dateauthmax
    ) => {
      setIsLoading(true);
      newmap.current.dragging.disable();
      newmap.current.scrollWheelZoom.disable();
      newmap.current.doubleClickZoom.disable();
      newmap.current.boxZoom.disable();
      newmap.current.keyboard.disable();
      
      const { _southWest, _northEast } = bounds;
      const apiUrl = "urba/rokhas/api-geo/search/location";
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      const urlencoded = new URLSearchParams();
      urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
      urlencoded.append("xmin", "" + _southWest.lng);
      urlencoded.append("xmax", "" + _northEast.lng);
      urlencoded.append("ymin", "" + _southWest.lat);
      urlencoded.append("ymax", "" + _northEast.lat);
      urlencoded.append("cle", cle);
      urlencoded.append("datedepotmin", datedepotmin);
      urlencoded.append("datedepotmax", datedepotmax);

      urlencoded.append("datedelivmin", dateauthmin);
      urlencoded.append("datedelivmax", dateauthmax);

      try {
        const response = await fetch(apiUrl, {
          signal: abortControllerRef.current.signal,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: urlencoded,
        });

        if (!response.ok) {
          setIsLoading(false);
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        var LOIcon = L.divIcon({
          html: `
          <svg width="34" height="38" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 23.8711 0.576173 26.6076 1.6192 29.1003C5.94533 40.912 21.1104 49 21.1104 49C21.1104 49 39.7389 39.0648 41.5928 25.1361C41.8599 23.7989 42 22.4158 42 21Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6509 24.7849L38.6318 24.8814L38.619 24.9789C37.8426 30.8763 33.4427 36.2795 28.6948 40.4277C26.3812 42.4489 24.1126 44.0616 22.42 45.1691C21.9283 45.4908 21.4872 45.7686 21.1104 46C20.8599 45.8462 20.5811 45.672 20.2779 45.4779C18.8615 44.5714 16.9286 43.2433 14.8719 41.5641C10.6804 38.142 6.31788 33.5375 4.4362 28.3436L4.41287 28.2792L4.38668 28.2159C3.4944 26.0601 3 23.691 3 21.1975C3 11.1473 11.0589 3 21 3C30.9411 3 39 11.1473 39 21.1975C39 22.4283 38.8796 23.6276 38.6509 24.7849Z" fill="#059669"/>
            <path d="M29 21C29 16.5817 25.4183 13 21 13C16.5817 13 13 16.5817 13 21C13 25.4183 16.5817 29 21 29C25.4183 29 29 25.4183 29 21Z" fill="white"/>
          </svg>
        `,
          className: "custom-icon",
          iconSize: [34, 38], 
        });

        var LOIcon1 = L.divIcon({
          html: ` 
         <svg width="34" height="38" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 23.8711 0.576173 26.6076 1.6192 29.1003C5.94533 40.912 21.1104 49 21.1104 49C21.1104 49 39.7389 39.0648 41.5928 25.1361C41.8599 23.7989 42 22.4158 42 21Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.6509 24.7849L38.6318 24.8814L38.619 24.9789C37.8426 30.8763 33.4427 36.2795 28.6948 40.4277C26.3812 42.4489 24.1126 44.0616 22.42 45.1691C21.9283 45.4908 21.4872 45.7686 21.1104 46C20.8599 45.8462 20.5811 45.672 20.2779 45.4779C18.8615 44.5714 16.9286 43.2433 14.8719 41.5641C10.6804 38.142 6.31788 33.5375 4.4362 28.3436L4.41287 28.2792L4.38668 28.2159C3.4944 26.0601 3 23.691 3 21.1975C3 11.1473 11.0589 3 21 3C30.9411 3 39 11.1473 39 21.1975C39 22.4283 38.8796 23.6276 38.6509 24.7849Z" fill="#F59E0B"/>
<path d="M29 21C29 16.5817 25.4183 13 21 13C16.5817 13 13 16.5817 13 21C13 25.4183 16.5817 29 21 29C25.4183 29 29 25.4183 29 21Z" fill="white"/>
</svg>
      `,
          className: "custom-icon",
          iconSize: [34, 38],
        });

        const regionBounds = regionpub.features.map((feature) => {
          const latlngs = feature.geometry.coordinates[0].map((coord) => [
            coord[1],
            coord[0],
          ]);
          return L.polygon(latlngs).getBounds();
        });
        function adjustPopupDirection(marker, map) {
          const markerPixelPosition = map.latLngToContainerPoint(
            marker.getLatLng()
          );
          const mapSize = map.getSize();
          const spaceAbove = markerPixelPosition.y;
          const spaceBelow = mapSize.y - markerPixelPosition.y;
          let popupAnchor;
          if (spaceBelow > spaceAbove) {
            popupAnchor = [0, 10];
          } else {
            popupAnchor = [0, -300];
          }
          return popupAnchor;
        }
        const displayDataOnMapcluster = (data, displaydatacluster) => {
          if (!displaydatacluster) return;
          markerClusterGroupRef.current.clearLayers();
          clearMapLayers();
        
          const createMarker = (item) => {
            var icons =
              item.categorie_desc == "Grands projets" ? LOIcon : LOIcon1;
            const marker = L.marker([item.latitude, item.longitude], {
              icon: icons,
            });
        
            let hoverTimeout;
            marker.on("mouseover", async () => {
              const popupAnchor = adjustPopupDirection(marker, newmap.current);
              const initialPopupContent = `<div class="popup-loading"></div>`;
              
              // Disable map interactions
              newmap.current.dragging.disable();
              newmap.current.scrollWheelZoom.disable();
              newmap.current.doubleClickZoom.disable();
              newmap.current.boxZoom.disable();
              newmap.current.keyboard.disable();
            
              marker
                .bindPopup(initialPopupContent, {
                  closeButton: false,
                  autoPan: false,
                  keepInView: true,
                  closeOnClick: false,
                  offset: popupAnchor,
                  className: "custom-popup",
                })
                .openPopup();
            
              try {
                const detailedData = await fetchDetailedData(item.id);
            
                const popupContent = detailedData
                  ? `<div class="popup">
                       <div class="popup-content ${isLngAr ? "rtl" : "ltr"}">
                         <div class="data-title">
                           <div class="valeur-reference">${detailedData.reference}</div>
                         </div>
                         <hr style="border: 1px #D1D5DB;width: 75%;">
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Date de délivrance"
                           )}</span></div>
                           <div class="valeur">${detailedData.dateDelivrance}</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Numéro d'autorisation"
                           )}</span></div>
                           <div class="valeur">${detailedData.numAutorisation}</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Titre foncier"
                           )}</span></div>
                           <div class="valeur">${detailedData.tfonciers}</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Surface"
                           )}</span></div>
                           <div class="valeur">${detailedData.s_terrain} m&sup2;</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Nature de projet"
                           )}</span></div>
                           <div class="valeur">${detailedData.nature}</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Catégorie de projet"
                           )}</span></div>
                           <div class="valeur">${detailedData.categorie}</div>
                         </div>
                         <div class="data-item">
                           <div class="prefixinfo"><span class="label-data-item">${tNR(
                             "Consistance"
                           )}</span></div>
                           <div class="valeur">${detailedData.consistence}</div>
                         </div>
                         <hr style="border: 1px #D1D5DB; width: 75%;">
                         <div class="data-item">
                           <div><i class="fa-light fa-landmark " style="color: #373937;"></i></div>
                           <span class="label-adresses">
                             ${isLngAr ? (handleSearch(detailedData.prefecture, 'province') || detailedData.prefecture) : detailedData.prefecture}
                           </span> 
                         </div>
                         <div class="data-item">
                           <div><i class="fa-light fa-map " style="color: #373937;"></i></div>
                           <span class="label-adresses">
                             ${isLngAr ? (handleSearch(detailedData.commune, 'commune') || detailedData.commune) : detailedData.commune}
                           </span> 
                         </div>
                         <div class="data-item">
                           <div><i class="fa-light fa-location-dot " style="color: #646568;"></i></div>
                           <span class="label-adresses">${detailedData.adresseProjet}</span> 
                         </div>
                       </div>
                     </div>`
                  : `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`;
                marker.setPopupContent(popupContent);
              } catch (error) {
                console.error("Error fetching detailed data:", error);
                marker.setPopupContent(
                  `<p>ID: ${item.id}</p><p>Error fetching data</p>`
                );
              } finally {
                // Re-enable map interactions after popup content is updated
                marker.on("popupclose", () => {
                  newmap.current.dragging.enable();
                  newmap.current.scrollWheelZoom.enable();
                  newmap.current.doubleClickZoom.enable();
                  newmap.current.boxZoom.enable();
                  newmap.current.keyboard.enable();
                });
              }
            });
            
            
        
            document.addEventListener("mouseover", (event) => {
              const popupElement = document.querySelector(".leaflet-popup");
              if (popupElement && popupElement.contains(event.target)) {
                clearTimeout(hoverTimeout);
              }
            });
        
            document.addEventListener("mouseout", (event) => {
              const popupElement = document.querySelector(".leaflet-popup");
              if (popupElement && popupElement.contains(event.target)) {
                hoverTimeout = setTimeout(() => {
                  marker.closePopup();
                }, 500);
              }
            });
        
            return marker;
          };
        
          const maxMarkers = 2000; 
        
          if (data.length > 0) {
            let markerCount = 0; 
        
            data.forEach((item) => {
              if (markerCount >= maxMarkers) return; 
        
              const pointGeoJSON = {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [item.longitude, item.latitude],
                },
              };
        
              let isInsideAnyPolygon = false;
        
              marocpolygn.features.forEach((feature) => {
                if (booleanPointInPolygon(pointGeoJSON, feature)) {
                  isInsideAnyPolygon = true;
                }
              });
        
              if (isInsideAnyPolygon) {
                const marker = createMarker(item);
                markerClusterGroupRef.current.addLayer(marker);
                markerCount++;
              }
            });
        
            newmap.current.addLayer(markerClusterGroupRef.current); 
          }
        };
        
        const displayDataOnMap = (data,datadisplayin) => {
          if(!datadisplayin){            
            markerClusterGroupRef.current.clearLayers();
            clearMapLayers();
            return;}
          if (!markersRef.current) {
            markersRef.current = [];
          }

          markersRef.current.forEach((marker) => {
            newmap.current.removeLayer(marker);
          });
          markersRef.current = [];
            geoJsonLayerregion = L.geoJSON(regionpub, {
              style: (feature) => ({
                color: feature.properties.color || "#3388ff",
                weight: 3,
                opacity: 0,
                fillOpacity: 0.5,
                fillColor: feature.properties.color || "#3388ff",
              }),
            }).addTo(newmap.current);
         
   

          const values = data.map((item) => item.nbDossier);
          const maxValue = Math.max(...values);

          L.geoJSON(regionpub, {
            style: (feature) => ({
              weight: 0,
              opacity: 0,
              fillOpacity: 0,
            }),
            onEachFeature: (feature, layer) => {
              const coordinates = feature.geometry.coordinates[0];
              const centroid = calculateCentroid(coordinates);
              const latitude = centroid[1];
              const longitude = centroid[0];

              const regionData = data.find(
                (item) => item.region === feature.properties.NAME
              );
              if (regionData) {
                const radius = calculateRadius(regionData.nbDossier, maxValue);
                const icon = createIcon(regionData, radius);

                const marker = L.marker([latitude, longitude], { icon }).addTo(
                  newmap.current
                );

                markersRef.current.push(marker);
              }

              const buttonId = `zoom-button-${feature.properties.NAME.replace(
                /\s+/g,
                "-"
              )}`;
              const popupContent = `
          <div class="popup">
            <div class="popup-content-reg">
              <div class="data-itemReg" id="${buttonId}">
                <div class="valeurReg">${feature.properties.NAME}</div>
              </div>
              <div class="zoom-button"></div>
            </div>
          </div>
        `;

        layer.on("click", function (e) {
          if (newmap.current.getZoom() < 10) {
            this.bindPopup(popupContent, {
              autoPan: false,
              keepInView: true,
              closeOnClick: false,
              className: "custom-popup",
            });
            this.openPopup();
        
            const zoomButton = document.getElementById(buttonId);
        
           
            const layerRef = this;
        
            const zoomHandler = function () {
              const mouseLatLng = e.latlng;
              zoomToMousePointer(mouseLatLng);
              zoomButton.style.display = "none";
              zoomButton.removeEventListener("click", zoomHandler);
              
              layerRef.closePopup();
            };
        
            zoomButton.addEventListener("click", zoomHandler);
          } else {
            this.closePopup();
            newmap.current.removeLayer(this);
          }
        });
        
            },
          }).addTo(newmap.current);
        };

        function zoomToMousePointer(mouseLatLng) {
          const zoomLevel = 11;
          newmap.current.setView(mouseLatLng, zoomLevel);
        }
        const calculateCentroid = (coordinates) => {
          let x = 0;
          let y = 0;
          let z = 0;
          coordinates.forEach((coord) => {
            let latitude = (coord[1] * Math.PI) / 180;
            let longitude = (coord[0] * Math.PI) / 180;
            x += Math.cos(latitude) * Math.cos(longitude);
            y += Math.cos(latitude) * Math.sin(longitude);
            z += Math.sin(latitude);
          });
          let total = coordinates.length;
          x = x / total;
          y = y / total;
          z = z / total;
          let centralLongitude = Math.atan2(y, x);
          let centralSquareRoot = Math.sqrt(x * x + y * y);
          let centralLatitude = Math.atan2(z, centralSquareRoot);
          return [
            (centralLongitude * 180) / Math.PI,
            (centralLatitude * 180) / Math.PI,
          ];
        };

        const createIcon = (item, radius) => {
          const size = Math.max(radius / 10, 20);
          const iconHtml = `
<div class="circle-i12"

style="
  width: ${size - 8}px;
  height: ${size - 8}px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.6); /* Solid red inner circle */
  border: 1px solid white; /* White line as the border */
  box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.3); /* Outer light red circle */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
">
  ${formatNumber(item.nbDossier)}
</div>
  `;
          return L.divIcon({
            html: iconHtml,
            className: "custom-icon",
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 2],
          });
        };

        if (result.groupby === "dossier") {
          clearMapLayers();
          setTimeout(() => {
            const elements = document.querySelectorAll(".circle-i12");
            elements.forEach(element => {
              element.style.visibility = "hidden";
            });
          }, 100); 
          displayDataOnMapcluster(result.data,displaydatacluster);
          setdisplaydatacluster(false)
        } else{
          clearMapLayers();
          displayDataOnMap(result.data,displaydata);
          setdisplaydata(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
      // Disable map interactions
      newmap.current.dragging.enable();
      newmap.current.scrollWheelZoom.enable();
      newmap.current.doubleClickZoom.enable();
      newmap.current.boxZoom.enable();
      newmap.current.keyboard.enable();
      if (userMarker) {
        userMarker.addTo(newmap.current);
      }
    },
    []
  );
  const formatNumber = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(0) + "k";
    }
    return num.toString();
  };
  useEffect(() => {
    if (!newmap.current) {
      const initialView = {
        lat: 30.002517,
        lng: -14.224784,
        zoom: 6,
      };

      const mapInstance = L.map(mapRef.current, {
        center: [initialView.lat, initialView.lng],
        zoom: initialView.zoom,
        dragging: true,
        renderer: L.canvas(),
      });

      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(mapInstance);

      const fullscreenControl = new L.Control.Fullscreen({
        position: "topright",
      });
      mapInstance.addControl(fullscreenControl);

      mapInstance.removeControl(mapInstance.zoomControl);

      const zoomControl = new L.Control.Zoom({
        position: "bottomright", 
      });
      mapInstance.addControl(zoomControl);

      mapInstance.on("enterFullscreen", function () {
        console.log("Entered fullscreen");

      });

      mapInstance.on("exitFullscreen", function () {
        console.log("Exited fullscreen");
      });
      const createCustomButton = (iconSVG, onClick) => {
        const div = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );
        div.innerHTML = `
          <span class="flex justify-center items-center w-full h-full">
            ${iconSVG}
          </span>
        `;
        div.style.backgroundColor = "var(--mapping-slate-000)";
        div.style.border = "1px solid var(--mapping-slate-200)";
        div.style.width = "40px";
        div.style.height = "40px";
        div.style.borderRadius = "12px";
        div.style.cursor = "pointer";
        div.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
        div.style.transition = "transform 0.2s, box-shadow 0.2s";

        div.onmouseover = () => {
          div.style.transform = "scale(1.1)";
          div.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.3)";
        };

        div.onmouseout = () => {
          div.style.transform = "scale(1)";
          div.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
        };

        div.onclick = onClick;

        return div;
      };

      // Add locate button
      const locateButton = L.control({ position: "topleft" });
      locateButton.onAdd = function () {
        const iconSVG = `
          <svg xmlns="http://www.w3.org/2000/svg" fill="#0369A1" height="24px" viewBox="0 -960 960 960" width="24px">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style="stop-color:#3B82F6;stop-opacity:1" />
                <stop offset="100%" style="stop-color:#1E40AF;stop-opacity:1" />
              </linearGradient>
            </defs>
            <path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z" 
            fill="#0369A1" />
          </svg>
        `;
        return createCustomButton(iconSVG, () => {
          mapInstance.locate({ setView: true, maxZoom: 16 });
        });
      };
      locateButton.addTo(mapInstance);

      // Add reset button
      const resetButton = L.control({ position: "topleft" });
      resetButton.onAdd = function () {
        const iconSVG = `
          <svg xmlns="http://www.w3.org/2000/svg" fill="#0369A1" height="24px" viewBox="0 0 24 24" width="24px">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
          </svg>
        `;
        return createCustomButton(iconSVG, () => {
          mapInstance.setView(
            [initialView.lat, initialView.lng],
            initialView.zoom
          );
        });
      };
      resetButton.addTo(mapInstance);

      const customIcon = L.icon({
        iconUrl:
          "data:image/svg+xml;charset=utf-8," +
          encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" fill="#3B82F6" height="24px" viewBox="0 -960 960 960" width="24px">
            <path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z"/>
          </svg>
        `),
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        // popupAnchor: [0, -8],
      });

      mapInstance.on("locationfound", (e) => {
        if (userMarker) {
          userMarker.setLatLng(e.latlng);
        } else {
          const newMarker = L.marker(e.latlng, { icon: customIcon })
            .addTo(mapInstance)
            .openPopup();
          setUserMarker(newMarker);
        }
      });

      mapInstance.on("locationerror", (e) => {
        alert("Location access denied.");
      });

      newmap.current = mapInstance;
    }

    return () => {
      if (newmap.current) {
        newmap.current.remove();
        newmap.current = null;
      }
    };
  }, []);
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const calculateBoundsDifference = (bounds1, bounds2) => {
    const latLngs1 = [bounds1.getNorthWest(), bounds1.getSouthEast()];
    const latLngs2 = [bounds2.getNorthWest(), bounds2.getSouthEast()];

   
    const width1 = Math.abs(latLngs1[0].lng - latLngs1[1].lng);
    const height1 = Math.abs(latLngs1[0].lat - latLngs1[1].lat);

    const width2 = Math.abs(latLngs2[0].lng - latLngs2[1].lng);
    const height2 = Math.abs(latLngs2[0].lat - latLngs2[1].lat);

    const area1 = width1 * height1;
    const area2 = width2 * height2;

    return Math.abs(area1 - area2);
  };

  let previousBounds = null;
  useEffect(() => {
    if (!newmap.current) {
      return;
    }
    const staticBounds = {
      _southWest: { lat: 23.200960808078566, lng: -31.508789062500004 },
      _northEast: { lat: 36.38591277287654, lng: 3.0761718750000004 },
    };
    newmap.current.on(
      "load",
      fetchData(
        staticBounds,
        searchCleRef.current,
        datedeptominRef.current,
        datedepotmaxRef.current,
        dateauthominRef.current,
        dateauthmaxRef.current
      )
    );
    const handleMoveEnd = debounce(() => {
      const currentBounds = newmap.current.getBounds();
      if (previousBounds) {
        const latDiff = calculateBoundsDifference(
          previousBounds,
          currentBounds
        );
        const LAT_THRESHOLD = 1;
        const LNG_THRESHOLD = 1;

        if (newmap.current.getZoom() >= 10) {
          fetchData(
            currentBounds,
            searchCleRef.current,
            datedeptominRef.current,
            datedepotmaxRef.current,
            dateauthominRef.current,
            dateauthmaxRef.current
          );
        }
      }

      previousBounds = currentBounds;
    }, 10);
    newmap.current.on("moveend", handleMoveEnd);
    newmap.current.on("zoomend", () => {
      if (
        newmap.current.getZoom() < 10 &&
        newmap.current.getZoom() >= 6 &&
       
        switcher.current
      ) {
        switcher.current = false;
        markerClusterGroupRef.current.clearLayers();
        fetchData(
          staticBounds,
          searchCleRef.current,
          datedeptominRef.current,
          datedepotmaxRef.current,
          dateauthominRef.current,
          dateauthmaxRef.current
        );
      } else if (newmap.current.getZoom() >= 10 && !switcher.current) {
        clearMapLayers();
        markerClusterGroupRef.current.clearLayers();
        switcher.current = true;
      } else if (newmap.current.getZoom() < 6) {
        switcher.current = true;
        markerClusterGroupRef.current.clearLayers();
        clearMapLayers();
      }
    });
  }, [
    fetchData,
    searchCleRef.current,
    datedeptominRef.current,
    datedepotmaxRef.current,
    dateauthominRef.current,
    dateauthmaxRef.current,
  ]);

  const handleFocus = (e) => {
    console.log("Focus:", e);
  };

  const handleBlur = (e) => {
    console.log("Blur:", e);
  };
  const handleChange = (dates, dateStrings) => {
    setDates(dateStrings);
    datedeptominRef.current = dateStrings[0];
    datedepotmaxRef.current = dateStrings[1];
  };
  const handleChangeAut = (dates, dateStrings) => {
    setDatesAut(dateStrings);
    dateauthominRef.current = dateStrings[0];
    dateauthmaxRef.current = dateStrings[1];
  };
  const fetchDetailedData = async (id) => {
    const apiUrl = "urba/rokhas/api-geo/search/popupInfo";

    const urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
    urlencoded.append("doid", "" + id);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch detailed data");
      }
      const detailedData = await response.json();
      return detailedData.data;
    } catch (error) {
      console.error("Error fetching detailed data:", error);
      return null;
    }
  };

  const calculateRadius = (value, maxValue) => {
    const minRadius = 400;
    const maxRadius = 550;
    const normalizedRadius =
      minRadius + (value / maxValue) * (maxRadius - minRadius);
    return Math.max(normalizedRadius, maxRadius / 2);
  };
  const clearMapLayers = () => {
    if (newmap && newmap.current) {
      newmap.current.eachLayer((layer) => {
        if (layer === geoJsonLayerregion || layer instanceof L.Circle ) {
          newmap.current.removeLayer(layer);
        }
      });

    
      if (markerClusterGroupRef.current) {
        markerClusterGroupRef.current.clearLayers();
      }
      if (userMarker !== null) {
        userMarker.addTo(newmap.current);
      }
    }
  };

  const filterItemsContainerStyle = classNames({
    [styles.filterItemsContainer]: true,
    [styles.notShow]: !showFilter,
    [styles.filterItemsContainerAppear]: showFilter,
  });

  const onChangeFilterButton = (isOpen) => {
    console.log("test+++", filterItemsContainerStyle);
    setShowFilter(isOpen);
  };
  const initialView = {
    lat: 30.002517,
    lng: -14.224784,
    zoom: 6,
  };

  const onSearchClick = () => {
    setSearchCle(searchCriteria);
    searchCleRef.current = searchCriteria;
    if (newmap.current) {
      newmap.current.setView(
        [initialView.lat, initialView.lng],
        initialView.zoom
      );
    }
  };

  const onSearchChange = (string) => {
    setSearchCriteria(string);
    if (string.length === 0) {
      setSearchCle("");
      searchCleRef.current = "";
      if (newmap.current) {
        newmap.current.setView(
          [initialView.lat, initialView.lng],
          initialView.zoom
        );
      }
    }
  };
  const { t: tNR } = useTranslation("geodata");
  const breadcrumbParts = [
    { text: tBC("Accueil"), link: "/" },
    { text: tBC("J’explore les indicateurs"), link: "/" },
    { text: tBC("Géoportail autorisations"), link: "/" },
  ];

  return (
    <div>
      <div className="breadcrumb">
        <BreadCrumb parts={breadcrumbParts} />
      </div>
      <div className={styles.containerstylesearch}>
        <div className={styles.pageTitle} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
          <span>{tNR("page_title")}</span>
        </div>
        <div className={styles.pageTitleDescription} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
          <p className={styles.descriptionContent} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
            {tNR("page_title_description")}
          </p>
        </div>
      </div>
      <div
        style={{
          margin: "0 5% 0 5%",
          width: "90%",
          height: "700px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingBottom: "50px",
        }}
      >
        <div className={styles.searchContainer}>
          <div className={styles.searchIcon}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.01 11H12.22L11.94 10.73C12.92 9.59 13.51 8.11 13.51 6.5C13.51 2.91 10.6 0 7.01001 0C3.42001 0 0.51001 2.91 0.51001 6.5C0.51001 10.09 3.42001 13 7.01001 13C8.62001 13 10.1 12.41 11.24 11.43L11.51 11.71V12.5L16.51 17.49L18 16L13.01 11ZM7.01001 11C4.52001 11 2.51001 8.99 2.51001 6.5C2.51001 4.01 4.52001 2 7.01001 2C9.50001 2 11.51 4.01 11.51 6.5C11.51 8.99 9.50001 11 7.01001 11Z"
                fill="#9CA3AF"
              />
            </svg>
          </div>
          <div className={styles.searchItemContainer}>
            <GlobalSearch
              loading={false}
              onChange={onSearchChange}
              onClick={onSearchClick}
              menu={[]}
              placeholder={tNR("ref_cle")}
            />
          </div>
          <div className={styles.filterContainer}>
            <FilterButton onChange={onChangeFilterButton} />
          </div>
        </div>
        <div className={filterItemsContainerStyle}>
          <div className={styles.customborderwrapper}>
            <span className={labelClass}>{tNR("depot_date")}</span>
            <Space
              direction="vertical"
              size={12}
              className={styles.customborder}
            >
              <RangePicker
                placeholder={[tNR("du"), tNR("au")]}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                onChange={handleChange}
                locale={locale}
              />
            </Space>
          </div>
          <div className={styles.customborderwrapper}>
            <span className={labelClass}>{tNR("aut_date")}</span>
            <Space
              direction="vertical"
              size={12}
              className={styles.customborder}
            >
              <RangePicker
                placeholder={[tNR("du"), tNR("au")]}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                onChange={handleChangeAut}
                locale={locale}
              />
            </Space>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: "90000000000000000",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <FadeLoader
            color="#8BB2AA"
            height={31}
            width={12}
            margin={20}
            radius={11}
            loading={isLoading}
          />
        </div>
        <div dir="ltr" ref={mapRef} className={styles.mapp}></div>
      </div>
    </div>
  );
};
export default StatMapv2;
