import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./authorization.module.css";
import GlobalSearch from "../../components/search/GlobalSearch";
import React, {createContext, useContext, useEffect, useRef, useState,useReducer} from "react";
import {useTranslation} from "react-i18next";
import Icon from '../../components/util/Icon';
import {
    ActivityCard,
    ActivityCardSkeleton,
} from "../../pagescomponents/respositorycard/RepositoryCard";
import {
    ACTIVITIY_TYPES_OBJECT,
} from "../../util/constants";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetActivityDetail,
    useGetAllCommune,
    useGetAllProvince, useGetAutoCompleteActivity,
    useGetRefActivitiesByCriteria,useGetActivityNatures
} from "../../api/caller";
import {Pagination, Tabs} from "antd";
import {FilterButton} from "../../components/filters/FilterButton";
import {Select} from "../../components/select/Select";
import classNames from "classnames";
import {EmptySearchData} from "../../components/emptyData/EmptyData";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {isEmptyString, scrollToTopOnMount, toLogin} from "../../util/functionsUtil";

const ActivityContext=createContext({
    activityCard:<></>, activityCode:"",wrappedPage:false,setActivityCard:()=>{},setActivityCode:()=>{},setWrappedPage:()=>{}
})
const refActivitiesListPage=()=>{
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const navigate=useNavigate();
    const [activityCard,setActivityCard]=useState(null);
    const [activityCode,setActivityCode]=useState(null);
    const [wrappedPage,setWrappedPage]=useState(false);
    const {t:tBC}=useTranslation("breadcrumb");
    const {t:tRA}=useTranslation("referentielAutorisation");
    const {getDirX}=useHandleTranslation()
    const searchPageBreadcrumbParts = [
        {text: tBC("Accueil"), link: '/'},
        {text: tBC("prepare_dossier"), link: '/'},
        {text: tBC("referentiel_autorisation"), link: '/refActivities'},
        {text: tBC("recherche_activite"), action: ()=>setWrappedPage(false)}
    ];
    const detailPageBreadcrumbPartsDetail = [
        ...searchPageBreadcrumbParts,
        {text: tBC("detail_activite"), action:()=>setWrappedPage(true)}
    ];
    const breadcrumbParts=!wrappedPage ? searchPageBreadcrumbParts:detailPageBreadcrumbPartsDetail

    const searchActivityClass=classNames({
        [styles.pageContent]:true,
        [styles.notDisplay]:wrappedPage,
        [styles.displayPageContent]:!wrappedPage
    })

    const detailActivityClass=classNames({
        [styles.pageContent]:true,
        [styles.notDisplay]:!wrappedPage,
        [styles.displayPageContent]:wrappedPage
    })
    return(
        <div id={"page"} style={{minHeight: "100vh"}}>
            <div className="breadcrumb">
                <BreadCrumb parts={breadcrumbParts}/>
            </div>
            <div  className={styles.pageTitleContainer + " responsive-web"}>
                <div className={styles.pageTitle}>{tRA("page_title_2")}</div>
                <div className={styles.pageTitleDescription}>{tRA("page_description_2")}</div>
            </div>
            <div className={styles.pageTitleContainerMobile + " responsive-mobile"}>
                <div className={styles.pageTitleMobile}>
                    <div className="cursor-pointer pt-2">
                        {wrappedPage && <i onClick={() => setWrappedPage(false)} className={`fa-solid fa-${getDirX()}-long`}></i>}
                        {!wrappedPage && <i onClick={() => navigate(-1)} className={`fa-solid fa-${getDirX()}-long`}></i>}
                    </div>
                    <span className={styles.pageTitleMobileItem}>{tRA("page_title_2")}</span>
                </div>
                <div className={styles.pageTitleDescription}>{tRA("page_description_2")}</div>
            </div>

            <ActivityContext.Provider
                value={{activityCode, activityCard,wrappedPage, setActivityCard, setActivityCode, setWrappedPage}}>
                <div className={styles.pageContentContainer}>
                    {!wrappedPage && <SearchActivityContent className={searchActivityClass}/>}
                    {wrappedPage && <DetailActivityContent className={detailActivityClass}/>}
                </div>
            </ActivityContext.Provider>
        </div>
    )
}
const SearchActivityContent = (props) => {
    const {type} = useParams();
    const {currLng,isLngAr,isLngFr}=useHandleTranslation();
    const [typeAutorization, setTypeAutorization] = useState({
        type,
        karazType: ACTIVITIY_TYPES_OBJECT.getKarazType(type)
    })
    const [activityCriteria, setActivityCriteria] = useState({
        noPage: 1,
        pageSize: 8,
        search: "",
        type: type,
        nature: ""
    });
    const [searchCriteria, setSearchCriteria]=useState("");
    const [prefix,setPrefix]=useState("");
    const [showFilter,setShowFilter]=useState(false);
    const {t:tRA}=useTranslation("referentielAutorisation");
    const {setActivityCode,setActivityCard,setWrappedPage}=useContext(ActivityContext);
    const getActivityNatures=useGetActivityNatures(typeAutorization.karazType,currLng);
    const getActivityData=useGetRefActivitiesByCriteria(activityCriteria,currLng);
   const getAutoCompleteActivity=useGetAutoCompleteActivity(prefix,typeAutorization.type,currLng)
    const authorizationSelectOptions=ACTIVITIY_TYPES_OBJECT.data.map((item)=>({label:tRA(item.CODE),value:item.TYPE}));

    useEffect(() => {
        const element = document.getElementById('page');
        if (getActivityData.isFetching && element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [getActivityData.isFetching]);

    const onSearchClick=()=>{
        if (searchCriteria===activityCriteria.search && activityCriteria.noPage===1) getActivityData.refetch();
        else{
            if(searchCriteria.length !==0) setActivityCriteria({...activityCriteria,noPage: 1,search:searchCriteria});
        }
    }
    const onSearchChange=(string)=>{
        setPrefix(string);
        setSearchCriteria(string);
        if (string.length===0) setActivityCriteria({...activityCriteria,search: ""});
    }
    const onSearchMenuClick=(string)=>{
        setSearchCriteria(string);
        if (string===activityCriteria.search) getActivityData.refetch();
        else{
            if(string.length !==0) setActivityCriteria({...activityCriteria,noPage:1,search:string});
        }
    }
    const onPaginationChange=(page, pageSize)=>{
        setActivityCriteria({...activityCriteria,noPage: page})
    }
    const onChangeFilterButton=(isOpen)=>{
        setShowFilter(isOpen);
    }
   const onActivityClick=(code)=>{
        const item=getActivityData.data?.page?.filter(element=>element.code===code)[0];
        const card= <ActivityCard data={item}/>
        setActivityCode(code);
        setActivityCard(card);
        const element = document.getElementById('page');
        element.scrollIntoView({ behavior: 'smooth' });
        setWrappedPage(true)
    }
    const onAuthorizationSelectChange=(type)=>{
        setTypeAutorization({
            type:ACTIVITIY_TYPES_OBJECT.getCode(type),
            karazType:ACTIVITIY_TYPES_OBJECT.getKarazType(ACTIVITIY_TYPES_OBJECT.getCode(type))
        })
        setActivityCriteria({...activityCriteria,type:ACTIVITIY_TYPES_OBJECT.getCode(type)});
    }
    const onNatureSelectChange=(nature)=>{
        setActivityCriteria({...activityCriteria,nature:nature??""});
    }
    const filterItemsContainerStyle=classNames({
        [styles.filterItemsContainer]:true,
        [styles.notShow]:!showFilter,
        [styles.filterItemsContainerAppear]:showFilter,
    })
    return (<div className={props.className}>
        <div className={styles.searchContainer}>
            <div className={`${styles.searchItemContainer}`}>
                <GlobalSearch loading={getActivityData.isFetching} onChange={onSearchChange} onClick={onSearchClick}
                              menu={getAutoCompleteActivity.data?.data || []} onMenuClick={onSearchMenuClick}
                              placeholder={tRA(`search_placeholder_${typeAutorization.type}`)}   />
            </div>
            <div className={styles.filterContainer}>
                <FilterButton onChange={onChangeFilterButton}/>
            </div>
        </div>
        <div className={filterItemsContainerStyle}>
            <Select label={tRA("page_select_1_title")}
                    active={authorizationSelectOptions.map(array=>array.value).indexOf(ACTIVITIY_TYPES_OBJECT.getType(typeAutorization.type)) + 1}
                    options={authorizationSelectOptions} onChange={onAuthorizationSelectChange} erasable/>
            <Select disabled={isEmptyString(typeAutorization.type)} label={tRA("page_select_2_title")}
                    options={typeAutorization.type.length === 0 ? null : getActivityNatures.data?.page}
                    loading={getActivityNatures.isFetching} onChange={onNatureSelectChange} search erasable/>
        </div>
        <div className={styles.tagsListMessage + " responsive-mobile"}>
            <div className={styles.tagsListMessage + " responsive-web"}>
                <span>{tRA("page_message_1")} </span>
                <span className={"text-primary flex gap-2"}>
            <a className={styles.tagsListLink}>
                {tRA("page_link_1")}
            </a>
                    {isLngFr && <Icon iconName='arrow-right' iconType='light'/>}
                    {isLngAr && <Icon iconName='arrow-left' iconType='light'/>}
            </span>
            </div>
        </div>
        <div className={styles.activityContentContainer}>
            {getActivityData.isFetched && !getActivityData.isFetching && getActivityData.data?.page && getActivityData.data?.page.map((item, index) =>
                <ActivityCard key={index} data={item}
                              withAction onclick={onActivityClick}/>)}
            {getActivityData.isFetching && Array.from({length: 8}).map((element, index) => <ActivityCardSkeleton
                key={index} withAction/>)}
        </div>
        {(getActivityData.isError || getActivityData.data?.page?.length === 0) && !getActivityData.isFetching &&
            <EmptySearchData search={searchCriteria}/>}
        {getActivityData.isFetched && getActivityData.data?.page &&
            <div className={styles.paginationContainer}>
                <Pagination current={activityCriteria.noPage} onChange={onPaginationChange}
                            pageSize={activityCriteria.pageSize} total={getActivityData.data?.pagination?.totalPage}
                            showSizeChanger={false}/>
            </div>
        }
        <div className={styles.tagsListMessage + " responsive-web flex gap-2"}>
            <span>{tRA("page_message_1")} </span>
            <span className={"text-primary flex gap-2"}>
            <span onClick={() => toLogin()} className={styles.tagsListLink}>
                {tRA("page_link_1")}
            </span>
                {isLngFr && <Icon iconName='arrow-right' iconType='light'/>}
                {isLngAr && <Icon iconName='arrow-left' iconType='light'/>}
            </span>
        </div>
    </div>)
}
const DetailActivityContent = (props) => {
    const {currLng} = useHandleTranslation();
    const {t: tRA} = useTranslation("referentielAutorisation");
    const [enabledReactQuery, setEnabledReactQuery] = useState({isActivityDetailEnabled: false});
    const {activityCard, activityCode, wrappedPage} = useContext(ActivityContext);
    const [isProvinceFetchEnabled, setProvinceFetchStatus] = useState(false)
    const getAllProvince = useGetAllProvince(isProvinceFetchEnabled, currLng);
    const [localite,setLocalite]=useState({province:"",communeCode:""});
    const getAllCommune=useGetAllCommune(localite.province,currLng);
    const getActivityDetail=useGetActivityDetail(localite.communeCode,activityCode,currLng,enabledReactQuery.isActivityDetailEnabled)
    const onProvinceChange=(province)=>{
        setLocalite({...localite,province});
    }
    const onCommuneChange=(commune)=>{
       if(!!commune) {
           setLocalite({...localite, communeCode: commune});
           setEnabledReactQuery({...enabledReactQuery, isActivityDetailEnabled: true});
       }
    }
    const onProvinceSelectClick= (value)=>{
      setProvinceFetchStatus(true);
    }
    const onCommuneSelectClick= (isOpen)=>{
      if(!isOpen && !isEmptyString(localite.province)) getAllCommune.refetch();
    }
    const isErrorInGetActivities=getActivityDetail.isError
    const isSuccessInGetActivities=getActivityDetail.data && enabledReactQuery.isActivityDetailEnabled && getActivityDetail.isSuccess && !getActivityDetail.isFetching
    const items =[
        {
            key: '1',
            label: tRA("page_tab_1_title"),
            children: <TabItem data={getActivityDetail.data?.etapes} dataLabel="steps"/>,
        },
        {
            key: '2',
            label: tRA("page_tab_2_title"),
            children: <TabItem data={getActivityDetail.data?.docsRequis} dataLabel="doc-request"/>,
        },
        {
            key: '3',
            label: tRA("page_tab_3_title"),
            children: <TabItem data={getActivityDetail.data?.membreCom} dataLabel="commission"/>,
        },
        {
            key: '4',
            label: tRA("page_tab_4_title"),
            children: <TabItem data={getActivityDetail.data?.docDelivr} dataLabel="doc-delivery"/>,
        }
    ]
    const ontabChange=(activeKey)=>{
        const element = document.querySelector(`[data-node-key='${activeKey}']`);
        if (element ) {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }
    return(
    <div className={props.className}>
        {activityCard}
        {
           wrappedPage && <>
        <div className={styles.detailActivityTitleContainer}>
            <div className={styles.detailActivityTilte}>{tRA("page_subtitle_1")}</div>
            {!isSuccessInGetActivities && <div className={styles.detailActivityDescription}>{tRA("page_subdescription_1")}</div>}
        </div>
        <div className={styles.localiteFilterContainer}>
            <Select label={tRA("page_select_3_title")} options={getAllProvince.data?.page} onChange={onProvinceChange}
                    loading={getAllProvince.isFetching} onClick={onProvinceSelectClick} search/>
            <Select disabled={isEmptyString(localite.province)} label={tRA("page_select_4_title")} options={getAllCommune.data?.page.map((item)=>({...item,value:item.code}))}
                    onChange={onCommuneChange} loading={getAllCommune.isFetching} onClick={onCommuneSelectClick} error={isErrorInGetActivities} search/>
            {getActivityDetail.isFetching && <div className={styles.filterloader}><i className="fas fa-cog fa-spin blue-color"></i></div>
            }
        </div>
        {isErrorInGetActivities &&
             <div className={styles.activityError}>
              <i className="fa-light fa-circle-exclamation"/>
             <span>{tRA("page_procedure_not_found")}</span>
        </div>
        }
        {isSuccessInGetActivities &&
            <>
             <DetailActivityTitleDescription title={tRA("page_subtitle_2")} description={getActivityDetail.data.intitule}/>
             <DetailActivityTitleDescription title={tRA("page_subtitle_3")} description={getActivityDetail.data.description}/>
             <Tabs onChange={ontabChange} defaultActiveKey="1" items={items}/>
                 </>
             }
            </>
}
    </div>
)
}
const DetailActivityTitleDescription = (props)=>{
    return (
        <div className={styles.detailActivityParagraphContainer}>
        <div className={styles.detailActivityParagraphTitle}>{props.title}</div>
        <div className={styles.detailActivityParagraphDescription}>{props.description}</div>
        </div>
)
}
const DetailActivityTabItem=(props)=>{
    return(
        <div className={styles.detailActivityTabItemContainer}>
            <div className={styles.detailActivityTabItemIconContainer}>
                <i className={"fa-solid fa-" +props.icon+" "+styles.detailActivityTabItemIcon}></i>
            </div>
            <div className={styles.detailActivityTabItemTextContainer}>
                <div className={styles.detailActivityTabItemTitle}>{props.title}</div>
                <div className={styles.detailActivityTabItemDescription}>{props.description}</div>
            </div>
        </div>
    )
}

const TabItem=(props)=>{
    const getIcon=(profile,dataType)=>{
        switch (dataType){
            case "steps":
                switch (profile.profile){
                    case "Administration": return "landmark";
                    case "Membre de commission":return "users";
                    case "Citoyen":return "user";
                    case "Architecte":return "user-helmet-safety";
                    case "Ingénieur spécialisé":return "user-helmet-safety";
                    case "Ingénieur géomètre topographe":return "user-helmet-safety";
                    case "Représentant Promoteur":return "user-helmet-safety";
                    case "Représentant opérateur télécom":return "user";
                default: return "xmark";
            }
            case "doc-request":
                switch (profile.profile){
                    case "Générer par Rokhas": return "file-signature";
                    case "Attacher par demandeur":return "file-arrow-up";
                    default: return "xmark";
                }
            case "doc-delivery": return "file-arrow-up"
            case "commission": return "user-tie"
            default :
                return "circle-exclamation"
        }

    }
    if(Array.isArray(props.data)) return <div className={styles.detailActivityTabItemsContainer}>{(props.data.map((element,index)=><DetailActivityTabItem key={index} title={element.intitule} icon={getIcon(element,props.dataLabel)} description={element.description}/>))}</div>
    return  <></>;
}
export default refActivitiesListPage;