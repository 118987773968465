import React, { useEffect, useState } from "react";

import "./folderState.css";
import { TrackFolder } from "../../asset/images/svgImages";
import { TrackFolderWithOutBG } from "../../asset/images/svgImages";

import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Stepper from "../../components/stepper";
import Modal from "../../components/modal/Modal";
import { useGetFolderDataByReference } from "../../api/caller";
import useHandleClassName from "../../hooks/useHandleClassName";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import Table from "../../components/table/Table";
import { COLUMNS_TRACK_FOLDER_CIN } from "../../holders/tableColumns";
import { useTranslation } from "react-i18next";
import "./folderState.css";
import { useLocation } from "react-router-dom";
import { useGetAllPermitsData } from "../../api/caller";


const FolderCinTrack = () => {
  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, size: 10 });
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sorting, setSorting] = useState([{ id: "", desc: false }]);
  const { t: tCR } = useTranslation("trackFolderByCin");
  const { t: tBC } = useTranslation("breadcrumb");
  const [enabled, setEnabled] = useState(false);
  const { currLng } = useHandleTranslation();
  const [open, setOpen] = useState(false);
  const [refTitle, setRefTitle] = useState("");
  const [reference, setReference] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [cin, setCin] = useState("");
  const [arrowIcon, setIcon] = useState("fa-sharp fa-solid fa-arrow-left");
  const { translate } = useHandleClassName();
  
  const {i18n}=useTranslation();
  const [isRefExist, setRefStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [selectedReference, setSelectedReference] = useState(null);
  const fetchFolderDataByCin = useGetAllPermitsData(
    location.state?.cin,
    searchTerm,
    currLng
  );

  const getFolderDataByReference = useGetFolderDataByReference(
    reference,
    enabled,
    currLng
  );

  const columns = React.useMemo(
    () => COLUMNS_TRACK_FOLDER_CIN(selectedReference, setSelectedReference),
    [selectedReference]
  );

  const breadcrumbParts = [
    { text: tBC("Accueil"), link: "/" },
    { text: tBC("Suivre_dossier"), link: "/suivi-dossier" },
    { text: tBC("Votre_dossier"), link: "/" },
  ];

  useEffect(() => {
    if (location.state?.cin) {
      setCin(location.state.cin);
    }
  }, [location.state]);
  useEffect(() => {
   
    const isLngAr=(i18n.language==="ar");
    const isLngFr=(i18n.language==="fr");
    if (isLngAr) {
      setIcon("fa-sharp fa-solid fa-arrow-right")
    }else{
      setIcon("fa-sharp fa-solid fa-arrow-left")
    }
  });
  useEffect(() => {
    if (fetchFolderDataByCin?.data) {
      console.log("cin data ", fetchFolderDataByCin?.data);
      const formattedData = fetchFolderDataByCin?.data;
      console.log("dataaaaaaaa  " + JSON.stringify(formattedData));
      setAllData(formattedData);
      setTotalElements(formattedData.length);
    }
  }, [fetchFolderDataByCin?.data]);

  useEffect(() => {
    const fetchDataByReference = async () => {
      if (selectedReference && selectedReference !== reference) {
        setReference(selectedReference);
        if (!enabled) setEnabled(true);
        try {
          await getFolderDataByReference.refetch();
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données par référence",
            error
          );
        }
      }
    };

    fetchDataByReference();
  }, [selectedReference, getFolderDataByReference, enabled]);

  useEffect(() => {
    if (
      getFolderDataByReference.isSuccess &&
      getFolderDataByReference.isFetched &&
      getFolderDataByReference.data?.body?.page
    ) {
      setRefStatus(true);
      if (getFolderDataByReference.data?.body?.page?.length === 0) {
        console.log("before setOpen ",getFolderDataByReference.data.body.page.length )
        setOpen(true);
        setRefStatus(false);
      }
      setRefTitle(reference);
      getFolderDataByReference.data?.body?.page?.map((item, index) => {
        if (item.status === "active") setActiveStep(index);
      });
      setActiveStep(null);
      for (
        let i = 0;
        i < getFolderDataByReference.data?.body?.page?.length;
        i++
      ) {
        if (getFolderDataByReference.data?.body?.page[i].status === "active") {
          setActiveStep(i);
          break;
        }
      }
    }
  }, [getFolderDataByReference.isLoading, getFolderDataByReference.isFetching]);
  useEffect(() => {
    if (
      !getFolderDataByReference.isFetching &&
      getFolderDataByReference.isFetched &&
      (getFolderDataByReference.isError ||
        !getFolderDataByReference.data?.body?.page)
    ) {
      console.log("setOpen2")
      setOpen(true);
      setRefStatus(false);
    }
  }, [getFolderDataByReference.isError, getFolderDataByReference.isFetching]);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.trim()) {
        setIsLoading(true);
        fetchFolderDataByCin
          .refetch()
          .then((response) => {
            const formattedData = response?.data;
            setTableData(formattedData);
            setTotalElements(formattedData.length);
          })
          .catch((error) => {
            console.error("Erreur lors de la recherche", error);
            setIsError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        fetchFolderDataByCin
          .refetch()
          .then((response) => {
            const formattedData = response?.data;
            setTableData(formattedData);
            setTotalElements(formattedData.length);
          })
          .catch((error) => {
            console.error("Erreur lors de la recherche avec CIN", error);
            setIsError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  useEffect(() => {
    if (tableData.length === 1 && !selectedReference) {
      setSelectedReference(tableData[0].reference);
    }
  }, [tableData, selectedReference]);


  const updateTableDataForPage = (page, size) => {
    console.log("updateTableDataForPage")
    const startIndex = (page - 1) * size;
    const endIndex = startIndex + size;
    setTableData(allData.slice(startIndex, endIndex)); 
  };

  useEffect(() => {
   
    updateTableDataForPage(pagination.page, pagination.size);
  }, [pagination, allData]);
  return (
    <>
      <div className="breadcrumb">
       
        <BreadCrumb parts={breadcrumbParts} />
      </div>

      <div className="container-box">
        <div className="container">
        <p className={`${translate("bold")} ed-request-title-mobile  `}><a  href="/suivi-dossier"><i className={`${arrowIcon} arrow_icon`}></i></a>{tCR("ed_request_title_mobile")}</p>
         <p className="intro-paragraph">
            <i className="fa-light fa-circle-info intro-icon"></i>
            {tCR("DESCRIPTION_1")}
          </p>
          <div>
            <div className="search-wrapper">
              <i className="fa fa-search search-icon"></i>
              <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={tCR("Rechercher_placeholder")}
                className="search-input"
              />
            </div>
            
            <Table
              rows={tableData}
              columns={columns}
              pagination={pagination}
              setPagination={setPagination}
              totalElements={totalElements}
              isLoading={isLoading}
              isError={isError}
              sorting={sorting}
              isSuccess
              setSorting={setSorting}
              emptyDataPrimaryMessage={tCR("empty_data_primary")}
              emptyDataSecondaryMessage={tCR("empty_data_secondary")}
              translate={tCR}
              backGroundColor={"#ffff"}
            />
          </div>
        </div>
      </div>

      {!isRefExist && (
        <div className="container-box folderNotSelect">
          <div
            className="flex-item hidden-mobile flex-item-center"
            style={{ height: "fit-content" }}
          >
            <TrackFolderWithOutBG />
            <div style={{ color: "rgb(3, 105, 161)" }}>
              {tCR("AUCUNE_DOSSIER")}
            </div>
          </div>
        </div>
      )}

      {isRefExist &&
        getFolderDataByReference.isFetched &&
        getFolderDataByReference.data?.body?.page &&
        !getFolderDataByReference.isFetching && (
          <>
            <div className="flex-column ed-container  mb-24">
              <div className="ed-res-title">
                <p style={{ textAlign: "center" }}>
                  <span className="ed-res-txt break-line-mobile">
                    {tCR("ed_response_title")} : &nbsp;
                  </span>
                  <span className="ed-reference">{refTitle}</span>{" "}
                </p>
              </div>
              <Stepper
                steps={getFolderDataByReference.data?.body?.page}
                maxSteps={"7"}
                activeStep={activeStep}
                className={"stepper-web"}
              />
              <Stepper
                steps={getFolderDataByReference.data?.body?.page}
                maxSteps={"3"}
                activeStep={activeStep}
                className={"stepper-mobile"}
              />
            </div>
          </>
        )}
      <Modal isOpen={open}>
        <>
          <div className={`ed-modal-title ${translate("bold")}`}>
            {tCR("modal_title")}
          </div>
          <div className={`ed-modal-message ${translate("regular")}`}>
            {tCR("modal_description")}
          </div>
          <button
            className={`ed-modal-close-btn ${translate("regular")}`}
            onClick={handleClose}
          >
            {tCR("modal_button")}
          </button>
        </>
      </Modal>
    </>
  );
};

export default FolderCinTrack;
