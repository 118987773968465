import React, {useEffect, useRef, useState} from "react";
import BasicTextFields from "../../pagescomponents/suivi-dossier/BasicTextFields";
import BasicButtons from "../../pagescomponents/suivi-dossier/BasicButtons";
import "./folderState.css";
import {TrackFolder} from "../../asset/images/svgImages";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Stepper from "../../components/stepper";
import Modal from "../../components/modal/Modal";
import {useGetFolderDataByReference,useGetAllPermitsData} from "../../api/caller";
import {SUIVI_DOSSIER_SCHEMA,SUIVI_DOSSIER_SCHEMA_CIN} from "../../holders/schemas";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {useTranslation} from "react-i18next";
import useHandleClassName from "../../hooks/useHandleClassName";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {scrollToTopOnMount} from "../../util/functionsUtil";
import {useNavigate} from "react-router-dom";
import {checkCinIfExiste} from "../../api/caller";


const FolderState = () => {
    useEffect(() => {
        scrollToTopOnMount();
    }, []);
    const [reference, setReference] = useState("");
    const [cin, setCin] = useState("");
    const [enabled, setEnabled] = useState(false);
    const {currLng}=useHandleTranslation();
    const navigate = useNavigate();

    const getFolderDataByReference = useGetFolderDataByReference(reference, enabled,currLng);

    const [activeStep,setActiveStep]=useState(null);
    const [open, setOpen] = useState(false);
    const [refTitle,setRefTitle]=useState('');
    const [isRefExist,setRefStatus]=useState(false);
    const {t:tED}=useTranslation("suiviDossier");
    const {t:tBC}=useTranslation("breadcrumb");
    const { t: tCR } = useTranslation("trackFolderByCin");
    const {translate}=useHandleClassName();
    const [searchType, setSearchType] = useState("folder");
    const referenceInputRef = useRef(null);
    const cinInputRef = useRef(null);
    const { mutate: executeCheckCinIfExiste } = checkCinIfExiste(); 
    const [modelDescription, setModelDescription] = useState("modal_description");

useEffect(() => {
    if (searchType === "folder" && referenceInputRef.current) {
        referenceInputRef.current.focus();
    } else if (searchType === "cin" && cinInputRef.current) {
        cinInputRef.current.focus();
    }
}, [searchType]);
    const {
        register: registerReference,
        handleSubmit: handleSubmitReference,
        formState: { errors: errorsReference },
        reset: resetReferenceForm,
    } = useForm({
        resolver: yupResolver(SUIVI_DOSSIER_SCHEMA), 
    });

    const {
        register: registerCin,
        handleSubmit: handleSubmitCin,
        formState: { errors: errorsCin },
        reset: resetCinForm
    } = useForm({
        resolver: yupResolver(SUIVI_DOSSIER_SCHEMA_CIN), 
    });

    useEffect(() => {
        if(getFolderDataByReference.isSuccess && getFolderDataByReference.isFetched && getFolderDataByReference.data?.body?.page) {
            setRefStatus(true);
            if(getFolderDataByReference.data?.body?.page?.length===0) {
                setOpen(true);
                setModelDescription(tED("modal_description"))
                setRefStatus(false);
            }
            setRefTitle(reference);
             getFolderDataByReference.data?.body?.page?.map((item, index) => {
                 if(item.status==="active") setActiveStep(index);
            })
            setActiveStep(null);
            for(let i=0;i<getFolderDataByReference.data?.body?.page?.length;i++){
                if (getFolderDataByReference.data?.body?.page[i].status==="active") {
                  setActiveStep(i);
                  break;
                }
            }
        }
    }, [getFolderDataByReference.isLoading,getFolderDataByReference.isFetching]);
    useEffect(() => {
        if (!getFolderDataByReference.isFetching && getFolderDataByReference.isFetched && (getFolderDataByReference.isError || !getFolderDataByReference.data?.body?.page)){
            setOpen(true)
            setModelDescription(tED("modal_description"))
            setRefStatus(false);
        }
    }, [getFolderDataByReference.isError,  getFolderDataByReference.isFetching]);


    const updateReference = async (form) => {
        console.log("updateReference")
        if(form.reference===reference) await getFolderDataByReference.refetch();
        setReference(form?.reference ||"");
        if (!enabled) setEnabled(true);
    }
    const updateCin = async (form) => {
        console.log("inside cin update ")
        const cinValue = form?.cin || "";
        if (cinValue) {
            if (!enabled) setEnabled(true);

            setCin(cinValue);
            navigate(`/suivi-dossier/detailsDossier`, { state: { cin: cinValue } });
        } else {
            console.log("Le champ CIN est vide");
            
        }
    };


        const handleClose = () => {
            setOpen(false);
        };

        const breadcrumbParts = [
            {text: tBC("Accueil"), link: '/'},
            {text: tBC("Suivre_dossier"), link: ''}
        ];
        const handleSearchTypeChange = (e) => {
            console.log("handleSearchTypeChange ",e.target.value)
            setSearchType(e.target.value);
            setReference(""); 
            setCin("");
            resetReferenceForm({
                reference: "",
            });
            resetCinForm({
                cin: ""
            });
            setReference("");
            setCin("");
            setRefStatus(false);  
            setActiveStep(null);  
            setOpen(false);  
            setEnabled(false); 
        };

        const checkCin = (form) => {
           executeCheckCinIfExiste(form?.cin, {
                  onSuccess: (result) => {
                       if(result==="SUCCESS"){
                        navigate(`/suivi-dossier/detailsDossier`, { state: { cin: form.cin } });   
                    }else{
                       // setModalMessage(); 
                        //setModalTitle(tRA("FAILED_ADD_PROP"))
                        setOpen(true);  
                        setModelDescription(tCR("modal_description_cin"));
                    }
                     
                  },
                  onError: (error) => {
                      const errorMessage = error?.response?.data || "An error occurred.";
                      //setModalMessage(); 
                      //setModalTitle(tRA("FAILED_ADD_PROP"))
                      //setIsModalOpen(true);  
                  },
              });
         
          };
        
        return (
            < div className={"max-content"}>
                <div className="breadcrumb">
                    <BreadCrumb parts={breadcrumbParts}/>
                </div>
            
                <div className="flex-center"  style={{margin: "5% 0 0"}}>
                    <div style={{justifyContent:"center"}} className="flex-item-search-bar" >
                    <div style={{maxWidth:"550px",flexGrow:1}} className="flex-item">
                        <div>
                            <p className={`${translate("bold")} ed-request-title  `}>{tED("ed_request_title")}</p>

                            <p className={translate("regular")} style={{color: '#374151', fontSize: '15px',lineHeight: 1.5,maxWidth:"400px"}}>{tED("ed_request_message1")}</p>
                        </div>

                       
                        <div className="search-type-selector search-type-selector-flex">

                              <label className="ml-3 mr-4 label-flex">
                                    <input
                                        type="radio"
                                        value="cin"
                                        checked={searchType === "cin"}
                                        onChange={handleSearchTypeChange}
                                        className="input-position"
                                    />
                                    {tED("cin_number")}
                                </label>
                                <label className="ml-3 mr-4 label-flex">
                                    <input
                                        type="radio"
                                        value="folder"
                                        checked={searchType === "folder"}
                                        onChange={handleSearchTypeChange}
                                        className="input-position"
                                    />
                                    {tED("folder_number")}
                                </label>
                              
                            </div>
                        <div className="ed-request-form-container">
                        {searchType === "folder" ? (
                            <><BasicTextFields register={registerReference} errorMessage={errorsReference?.reference?.message} onPressEnterKey={handleSubmitReference(updateReference)}
                                        isRequired={false} example={`${tED("ed_request_form_example")} : PCT-PSHM-ASB-1234/2022`} name={"reference"} placeholder={tED("ed_request_form_placeholder")}  /><BasicButtons title={`${tED("ed_request_form_button")}`} loading={getFolderDataByReference.isFetching} onClick={handleSubmitReference(updateReference,(errors)=>console.log("qwe",errors))} /></>
                        ) : (
                            <>
                            <BasicTextFields register={registerCin} errorMessage={errorsCin?.cin?.message} onPressEnterKey={handleSubmitCin(checkCin)}
                                            isRequired={false}example={`${tED("ed_request_form_example")} : BL123456`} name={"cin"} placeholder={tED("ed_request_form_placeholder_cin")}  />
                                            <BasicButtons title={`${tED("ed_request_form_button")}`} onClick={handleSubmitCin(checkCin)} />
                                            </>
        )}
        
                        </div>
                    </div>
                    <div className="flex-item hidden-mobile" style={{
                        height: 'fit-content',
                    }}>
                        <TrackFolder/>
                    </div>
                    </div>
                </div>
                
                {isRefExist && getFolderDataByReference.isFetched && getFolderDataByReference.data?.body?.page && !getFolderDataByReference.isFetching && (
                    <>
                        <div className="flex-column ed-container  mb-24">
                            <div className="ed-res-title">
                                <p style={{textAlign: "center"}}>
                                    <span className="ed-res-txt break-line-mobile">{tED("ed_response_title")} : &nbsp;</span>
                                    <span className="ed-reference">{refTitle}</span>{" "}
                                </p>
                            </div>
                            <Stepper steps={getFolderDataByReference.data?.body?.page} maxSteps={"7"} activeStep={activeStep} className={"stepper-web"}/>
                            <Stepper steps={getFolderDataByReference.data?.body?.page} maxSteps={"3"} activeStep={activeStep} className={"stepper-mobile"}/>
                        </div>
                    </>
                )}
               <Modal isOpen={open}>
                    <>
                        <div className={`ed-modal-title ${translate("bold")}`}>{tED("modal_title")}</div>
                        <div className={`ed-modal-message ${translate("regular")}`}>{modelDescription}</div>
                        <button className={`ed-modal-close-btn ${translate("regular")}`} onClick={handleClose}>{tED("modal_button")}</button>
                    </>
                </Modal>
            </div>

            
        );
    };

export default FolderState;