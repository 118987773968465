import React, { useCallback, useEffect, useRef, useState,useMemo  } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import globalStyles from "../globalstyles.module.css";
import "leaflet/dist/leaflet.css";
import "./Style.module.css";
import "./popupstyling.css";
import { DatePicker, Space } from "antd";
import styles from "./Style.module.css";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import classNames from "classnames";
import { Select } from "../../components/select/Select";
import GlobalSearch from "../../components/search/GlobalSearch";
import { FilterButton } from "../../components/filters/FilterButton";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import arEG from "antd/es/date-picker/locale/ar_EG";
import regionpub from "./regionsmajd.json";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {
  
  useGetLocationData
} from "../../api/caller";
import GeoMap from "./GeoMap";
import RegionMap from "./RegionMap";
import DossierMap from "./Dossiermap";
import ClearMap from "./ClearMap";


const Geoportail = () => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const mapRef = useRef(null);
  const markerClusterGroupRef = useRef(L.markerClusterGroup());
  const { isLngAr, isLngFr ,currLng} = useHandleTranstation();
  const labelClass = isLngAr
    ? `${styles.customlabel} ${styles.rtl}`
    : `${styles.customlabel} ${styles.ltr}`;
  const locale = isLngAr ? arEG : "";
  const [loadlang,setloadlang] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [searchCle, setSearchCle] = useState(searchCriteria);
  const searchCleRef = useRef(searchCriteria);
  const [dates, setDates] = useState(["", ""]);
  const [datesAut, setDatesAut] = useState(["", ""]);
  const abortControllerRef = useRef();
  const { RangePicker } = DatePicker;
  let geoJsonLayerregion = null;
  const datedeptominRef = useRef(dates[0]);
  const datedepotmaxRef = useRef(dates[1]);

  const dateauthominRef = useRef(datesAut[0]);
  const dateauthmaxRef = useRef(datesAut[1]);
  const [Bounds,setBounds]=useState({
    _southWest: {
      lat: 27.0,  
      lng: -17.0, 
    },
    _northEast: {
      lat: 37.5,  
      lng: -1.0,  
    },
  });
  const { data, isLoading, error,refetch } = useGetLocationData(
    Bounds,
    searchCleRef.current,  
    "",  
    "",
    "",
    "",
    false  
  );
 
  useEffect(()=>{
    refetch()
  },[Bounds,searchCleRef.current])
  console.log("refetch+++",data,Bounds)

  const { t: tBC } = useTranslation("breadcrumb");
  const newmap = useRef(null);

  const handleFocus = (e) => {
    console.log("Focus:", e);
  };

  const handleBlur = (e) => {
    console.log("Blur:", e);
  };
  const handleChange = (dates, dateStrings) => {
    setDates(dateStrings);
    datedeptominRef.current = dateStrings[0];
    datedepotmaxRef.current = dateStrings[1];
  };
  const handleChangeAut = (dates, dateStrings) => {
    setDatesAut(dateStrings);
    dateauthominRef.current = dateStrings[0];
    dateauthmaxRef.current = dateStrings[1];
  };
  const fetchDetailedData = async (id) => {
    const apiUrl = "urba/rokhas/api-geo/search/popupInfo";

    const urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
    urlencoded.append("doid", "" + id);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch detailed data");
      }
      const detailedData = await response.json();
      return detailedData.data;
    } catch (error) {
      console.error("Error fetching detailed data:", error);
      return null;
    }
  };

  const calculateRadius = (value, maxValue) => {
    const minRadius = 400;
    const maxRadius = 550;
    const normalizedRadius =
      minRadius + (value / maxValue) * (maxRadius - minRadius);
    return Math.max(normalizedRadius, maxRadius / 2);
  };
  const clearMapLayers = () => {
    if (newmap && newmap.current) {
      newmap.current.eachLayer((layer) => {
        if (layer === geoJsonLayerregion || layer instanceof L.Circle ) {
          newmap.current.removeLayer(layer);
        }
      });

    
      if (markerClusterGroupRef.current) {
        markerClusterGroupRef.current.clearLayers();
      }
      if (userMarker !== null) {
        userMarker.addTo(newmap.current);
      }
    }
  };

  const filterItemsContainerStyle = classNames({
    [styles.filterItemsContainer]: true,
    [styles.notShow]: !showFilter,
    [styles.filterItemsContainerAppear]: showFilter,
  });

  const onChangeFilterButton = (isOpen) => {
    console.log("test+++", filterItemsContainerStyle);
    setShowFilter(isOpen);
  };
  const initialView = {
    lat: 30.002517,
    lng: -14.224784,
    zoom: 6,
  };

  const onSearchClick = () => {
    setSearchCle(searchCriteria);
    searchCleRef.current = searchCriteria;
    if (newmap.current) {
      newmap.current.setView(
        [initialView.lat, initialView.lng],
        initialView.zoom
      );
    }
  };

  const onSearchChange = (string) => {
    setSearchCriteria(string);
    if (string.length === 0) {
      setSearchCle("");
      searchCleRef.current = "";
      if (newmap.current) {
        newmap.current.setView(
          [initialView.lat, initialView.lng],
          initialView.zoom
        );
      }
    }
  };
  const { t: tNR } = useTranslation("geodata");
  const breadcrumbParts = [
    { text: tBC("Accueil"), link: "/" },
    { text: tBC("J’explore les indicateurs"), link: "/" },
    { text: tBC("Géoportail autorisations"), link: "/" },
  ];
  const handleZoomChange = (newBounds) => {
    console.log("New bounds from GeoMap:", newBounds); 
   setBounds(newBounds);  
  };
  return (
    <div>
      <div className="breadcrumb">
        <BreadCrumb parts={breadcrumbParts} />
      </div>
      <div className={styles.containerstylesearch}>
        <div className={styles.pageTitle} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
          <span>{tNR("page_title")}</span>
        </div>
        <div className={styles.pageTitleDescription} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
          <p className={styles.descriptionContent} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
            {tNR("page_title_description")}
          </p>
        </div>
      </div>
      <div
        style={{
          margin: "0 5% 0 5%",
          width: "90%",
          height: "700px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingBottom: "50px",
        }}
      >
        <div className={styles.searchContainer}>
          <div className={styles.searchIcon}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.01 11H12.22L11.94 10.73C12.92 9.59 13.51 8.11 13.51 6.5C13.51 2.91 10.6 0 7.01001 0C3.42001 0 0.51001 2.91 0.51001 6.5C0.51001 10.09 3.42001 13 7.01001 13C8.62001 13 10.1 12.41 11.24 11.43L11.51 11.71V12.5L16.51 17.49L18 16L13.01 11ZM7.01001 11C4.52001 11 2.51001 8.99 2.51001 6.5C2.51001 4.01 4.52001 2 7.01001 2C9.50001 2 11.51 4.01 11.51 6.5C11.51 8.99 9.50001 11 7.01001 11Z"
                fill="#9CA3AF"
              />
            </svg>
          </div>
          <div className={styles.searchItemContainer}>
            <GlobalSearch
              loading={false}
              onChange={onSearchChange}
              onClick={onSearchClick}
              menu={[]}
              placeholder={tNR("ref_cle")}
            />
          </div>
          <div className={styles.filterContainer}>
            <FilterButton onChange={onChangeFilterButton} />
          </div>
        </div>
        <div className={filterItemsContainerStyle}>
          <div className={styles.customborderwrapper}>
            <span className={labelClass}>{tNR("depot_date")}</span>
            <Space
              direction="vertical"
              size={12}
              className={styles.customborder}
            >
              <RangePicker
                placeholder={[tNR("du"), tNR("au")]}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                onChange={handleChange}
                locale={locale}
              />
            </Space>
          </div>
          <div className={styles.customborderwrapper}>
            <span className={labelClass}>{tNR("aut_date")}</span>
            <Space
              direction="vertical"
              size={12}
              className={styles.customborder}
            >
              <RangePicker
                placeholder={[tNR("du"), tNR("au")]}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                onChange={handleChangeAut}
                locale={locale}
              />
            </Space>
          </div>
        </div>
        <GeoMap
          center={[30.002517, -14.224784]}  
          zoom={6}  
         
        >    
        {!isLoading&&data.groupby==="region" ? (
          
          <RegionMap data={data.data} regionpub={regionpub}  onZoomChange={handleZoomChange}  />
        ) : (
          <DossierMap data={data?.data} />
        )}
         </GeoMap>
        
      </div>
    </div>
  );
};
export default Geoportail;
