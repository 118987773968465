import styles from "../../pages/refactivities/authorization.module.css";
import sty from "../../pages/refJuri/jurid.module.css";

import React from "react";
import {useNavigate} from "react-router-dom";
import Btn from "../../components/button/Btn";
import useHandleClassName from "../../hooks/useHandleClassName";
import {Skeleton} from "antd";
import {RepositoryExample} from "../../pages/refactivities/RefActivitiesPage";
import {useTranslation} from "react-i18next";
import { Tag } from 'antd';
import TagCard from "../../components/cards/tagcard/TagCard";
import {
    JURIDIQUE_CATHEGORIE_OBJECT,
    OPTIONS_WITH_COLORS_OBJECT
} from "../../util/constants";
export const RepositoryCard=(props)=>{
    return (
        <div className={styles.cardContainer} onClick={props.onClick} >
            <div className={styles.cardContentContainer}>
                <img src={props.icon} alt={""}/>
                <p className={styles.cardTitle}>{props.label}</p>
            </div>
            {props.children}
        </div>
    )
}
export const ActivityCard = (props)=>{
    const {translate}=useHandleClassName();
    const {t:tRA}=useTranslation("referentielAutorisation");
    return (
        <div className={styles.activityCardContainer}>
            <div className={styles.activityCardText}>
                <div className={styles.activityCardTitle}>
                    <label>{props.data.intitule}</label>
                    <label className={styles.tagItem}>&zwnj;{props.data.typeAutorisation}</label>
                </div>
                <span className={styles.activityCardDescription}>{props.data.nature}</span>
            </div>
            {props.withAction && <div className={styles.activityCardButton}>
                <Btn paddingX="12" paddingY="14"
                     className={`blue-button ${translate("medium")} ${styles.activityCardBtn}`}
                     content={<div>{tRA("card_btn_title")}</div>} link={""} backgroundColor="#fff" color="#0369A1"
                     borderColor="#0369A1" onClick={()=>props.onclick(props.data.code)}/>
            </div>
            }
        </div>)
}
export const ActivityCardSkeleton=(props)=>{
    return(
        <div className={styles.activityCardSkeletonContainer}>
                <Skeleton active paragraph={{rows:2}} size={17} />
            {props.withAction && <div className={styles.activityCardButton}>
                <Skeleton.Input active size={17} />
            </div>}
        </div>
    )
}

export const RefJuridiqueCard = (props)=>{
    const {translate}=useHandleClassName();
    const {t:tRA}=useTranslation("juridique");
    return (
        <div className={sty.activityCardContainer}>
            <div className={sty.activityCardText}>
                <div className={sty.activityCardTitle} style={{color:"#4B5563"}}>
                    <label>{props.data.title}</label>
                    
                </div>
                <div className={sty.activityTag}>
                <TagCard  text={tRA(OPTIONS_WITH_COLORS_OBJECT.getType(props.data.type).label)}  color={"#F9FAFB"} backgroud={OPTIONS_WITH_COLORS_OBJECT.getType(props.data.type).color} size="12px" margin={"5px 2px"}/>
                <TagCard  text={tRA(props.data.typeRef)} color={"#0EA5E9"} backgroud={"#F3F4F6"} size="12px" margin={"5px 2px"}/>
                </div>
               
                <span className={sty.activityCardDescription}>{props.data.nature}</span>
            </div>
            {props.withAction && <div className={sty.activityCardButton}>
                <Btn
                     className={`blue-button ${translate("medium")} ${sty.activityCardBtn}`}
                     content={
                        <div className={sty.btnContent}>
            <i className="fa-light fa-eye hide mr-2"></i>
            {tRA("card_btn_titl")}
        </div>
                     } link={""} backgroundColor="#fff" color="#0369A1"
                     borderColor="#0369A1" onClick={()=>props.onclick(`/front/karazal/DownloadFile?gedId=${props.data.urlV}`)}/>
            </div>
            }
        </div>)
}
