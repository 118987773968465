import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./Style.module.css";



const GeoMap = ({ center, zoom, children }) => {
  const [isRtl, setIsRtl] = useState(false);
  // Determine the direction of the document for RTL support
  useEffect(() => {
    const direction = document.documentElement.getAttribute('dir');
    setIsRtl(direction === 'rtl');
  }, []);

  return (
    <MapContainer
      style={{
        transform: isRtl ? 'scaleX(-1)' : 'scaleX(1)',
        direction: isRtl ? 'rtl' : 'ltr',
      }}
      center={center}
      zoom={zoom}
      className={styles.mapp}
    >
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        maxZoom={20}
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
      />
      {children}
    </MapContainer>
  );
};

export default GeoMap;
