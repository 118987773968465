import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import suiviDossierAr from './asset/locales/ar/suivi_dossier.json';
import suiviDossierFr from './asset/locales/fr/suivi_dossier.json';
import breadCrumbAr from './asset/locales/ar/breadcrumb.json';
import breadCrumbFr from './asset/locales/fr/breadcrumb.json';
import formErrorsFr from './asset/locales/fr/form_errors.json';
import formErrorsAr from './asset/locales/ar/form_errors.json';
import headerAr from './asset/locales/ar/header.json';
import headerFr from './asset/locales/fr/header.json';
import referentielAutorisationFr from './asset/locales/fr/referentiel_autorisations.json'
import referentielAutorisationAr from './asset/locales/ar/referentiel_autorisations.json'
import articleFr from './asset/locales/fr/article.json'
import articleAr from './asset/locales/ar/article.json'
import singupAr  from './asset/locales/ar/signup.json'
import singupFr  from './asset/locales/fr/signup.json'
import optionsFr from './asset/locales/fr/select_options.json'
import optionsAr from './asset/locales/ar/select_options.json'
import enquetePublicFr from './asset/locales/fr/enquete_public.json'
import enquetePublicAr from './asset/locales/ar/enquete_public.json'
import componentsFr from './asset/locales/fr/components.json'
import componentsAr from './asset/locales/ar/components.json'
import nationalRankingFr from './asset/locales/fr/national_ranking.json'
import nationalRankingAr from './asset/locales/ar/national_ranking.json'
import tableFr from './asset/locales/fr/table.json'
import tableAr from './asset/locales/ar/table.json'
import serverErrorsAr from './asset/locales/ar/server_errors.json'
import serverErrorsFr from './asset/locales/fr/server_errors.json'
import RefJuridar from './asset/locales/ar/refJurid.json';
import RefJuridfr from './asset/locales/fr/refJurid.json';

import geodataAr from "./asset/locales/ar/geo_data.json";
import geodataFr from "./asset/locales/fr/geo_data.json";
import couverture_geofr from "./asset/locales/fr/couverture_geo.json";
import couverture_geoar from "./asset/locales/ar/couverture_geo.json";
import propActfr from "./asset/locales/fr/propAct.json";
import propActar from "./asset/locales/ar/propAct.json";

import trackFolderByCinFr from './asset/locales/fr/track_folder_cin.json'
import trackFolderByCinAr from './asset/locales/ar/track_folder_cin.json'


const storedLanguage = localStorage.getItem('selectedLanguage') || 'ar';




i18n.use(initReactI18next).init({
  supportedLngs: ["fr", "ar"],
  resources: {
    ar: {
      suiviDossier: suiviDossierAr,
      breadcrumb: breadCrumbAr,
      formErrors: formErrorsAr,
      header: headerAr,
      referentielAutorisation: referentielAutorisationAr,
      article: articleAr,
      signup: singupAr,
      options: optionsAr,
      enquete: enquetePublicAr,
      components: componentsAr,
      nationalRanking: nationalRankingAr,
      table: tableAr,
      serverErrors: serverErrorsAr,
      geodata: geodataAr,
      couverture_geo: couverture_geoar,
      juridique: RefJuridar,
      propAct: propActar,
      trackFolderByCin: trackFolderByCinAr,
    },
    fr: {
      suiviDossier: suiviDossierFr,
      breadcrumb: breadCrumbFr,
      formErrors: formErrorsFr,
      header: headerFr,
      referentielAutorisation: referentielAutorisationFr,
      article: articleFr,
      signup: singupFr,
      options: optionsFr,
      enquete: enquetePublicFr,
      components: componentsFr,
      nationalRanking: nationalRankingFr,
      table: tableFr,
      serverErrors: serverErrorsFr,
      geodata: geodataFr,
      couverture_geo: couverture_geofr, 
      juridique: RefJuridfr,
      propAct: propActfr,
      trackFolderByCin: trackFolderByCinFr,
    },
  },
  fallbackLng: "fr",
  lng: storedLanguage,
  interpolation: {
    escapeValue: false,
  },
});

  export default i18n;



