import React, { useEffect, useState,useRef } from "react";
import globalStyles from "../globalstyles.module.css";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import { useTranslation } from "react-i18next";
import GeoMap from "./GeoMap";
import MarkersLayer from "./MarkersLayer";
import classNames from "classnames";
import Filters from "./Filters";
import {
  useGetCommuneListData,
  useGetPrefectureListData,
  useGetcommunePerPrefecture,
  useGetDataEco,
  useGetAllProvince,
  useGetAllCommune
} from "../../api/caller";
import GlobalSearch from "../../components/search/GlobalSearch";
import { FilterButton } from "../../components/filters/FilterButton";
import {isEmptyString} from "../../util/functionsUtil";
import styles from "./Style.module.css";
import SelectForm from "../../components/select/SelectForm";
import {Select} from "../../components/select/Select";
import FilterControl from "./FilterControl";
import useHandleTranstation from "../../hooks/useHandleTranslation";
import { FadeLoader } from "react-spinners";


const Coverturegeo = () => {
  const { t: tBC } = useTranslation("breadcrumb");
  const { t: tNR } = useTranslation("couverture_geo");
  const [loading, setLoading] = useState(true);
  const { isLngAr, isLngFr } = useHandleTranstation();
  const [Markerslist, setMarkerslist] = useState([]);
  const [Loadingcommdata, setLoadingcommdata] = useState(true);
  const [LoadingPref, setPrefecturelis] = useState([""]);
  const [choosenPermis, setchoosenPermis] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchCle, setSearchCle] = useState(searchCriteria);
  const searchCleRef = useRef(searchCriteria);
  const [showFilter, setShowFilter] = useState(false);
  const [choosenProvince, setchoosenProvince] = useState("");
  const [choosenCommune, setchoosenCommune] = useState("");
  const [inquiryCriteria, setInquiryCriteria] = useState({
    page: 1,
    size: 8,
    search: "",
    commune: "",
    province:"",
    reference: "",
});
  // const fetchFolderDataByCin = useGetPcphPermitsData(20, "habiter", true);
  const fetchCommuneListData = useGetCommuneListData();
  const fetchprefectureListData = useGetPrefectureListData();
  const fetchcommunePer = useGetcommunePerPrefecture(10, true, inquiryCriteria.province);
  const mergedEcodata=useGetDataEco(17,true,choosenProvince);
  const getAllProvince = useGetAllProvince(true, isLngAr?"ar":"fr");
  const getAllCommune=useGetAllCommune(inquiryCriteria.province,isLngAr?"ar":"fr",'',true);
const urbaOptions=[
  { label: tNR("PH"), value: "Total_PH" },
  { label: tNR("PC"), value: "Total_PC" },
  { label: tNR("REF"), value: "TOTAL_RFC" },
  { label: tNR("REC"), value: "TOTAL_REC"},
  { label: tNR("DIM"), value: "TOTAL_DML" },
  { label: tNR("AN"), value: "TOTAL_AN" },
  { label: tNR("AP"), value: "TOTAL_AP" },
  { label: tNR("ODPRT"), value: "TOTAL_ODPRT" },
  { label: tNR("ANR1_2"), value: "TOTAL_ANR 1 / ANR 2"},
  { label: tNR("SD"), value: "TOTAL_SD" },
]

  const filterItemsContainerStyle = classNames({
    [styles.filterItemsContainer]: true,
    [styles.notShow]: !showFilter,
    [styles.filterItemsContainerAppear]: showFilter,
  });
  const breadcrumbParts = [
    { text: tBC("Accueil"), link: "/" },
    { text: tBC("Couverture"), link: "/" },
    { text: tBC("Couverture géographique"), link: "/" },
  ];
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([
    'Couverte',
    'Partiellement couverte',
    'Non couverte',
  ]);

  const handleFilterChange = (newSelectedOptions) => {
    setSelectedFilterOptions(newSelectedOptions);
  };
  useEffect(() => {
    if (fetchCommuneListData?.data) {
      if (fetchCommuneListData?.data?.data && fetchprefectureListData?.data) {
        setPrefecturelis(fetchprefectureListData?.data);
        if (Loadingcommdata) {
          setMarkerslist(fetchCommuneListData?.data?.data);
          setLoading(false);
          setLoadingcommdata(false);
        }
        return;
      }
    }
  }, [
    fetchCommuneListData?.data,
    fetchprefectureListData?.data,
    choosenProvince,
  ]);
  const onChangeFilterButton = (isOpen) => {
    setShowFilter(isOpen);
  };
  const onSearchClick = () => {
    setSearchCle(searchCriteria);
    searchCleRef.current = searchCriteria;
 
  };

  
  const onSearchChange = (string) => {
    setSearchCriteria(string);
    if (string.length===0){
      setSearchCle("");
      searchCleRef.current = "";
    }
  };
  const handleSelectChange=(values)=>{
    setchoosenPermis(values)
  }
  const onCommuneChange=(reference)=>{
    setInquiryCriteria({...inquiryCriteria,commune:reference??""});
    setchoosenCommune(reference??"")
}
const onProvinceChange=(province)=>{
  setInquiryCriteria({...inquiryCriteria,province:province ?? ""});
  setchoosenProvince(province)
}
const onCommuneSelectClick= (isOpen)=>{
  if(!isOpen)getAllCommune.refetch();
}
  return (
    <div>
      <div className="breadcrumb">
        <BreadCrumb parts={breadcrumbParts} />
      </div>
      <div className={styles.containerstylesearch }>
      <div className={styles.pageTitleContainer }>
        <div className={styles.pageTitle} style={{fontFamily:isLngAr?'Tajawal':'inter'}}><span>{tNR("page_title")}</span></div>
        <div className={styles.pageTitleDescription}>
          <p className={styles.descriptionContent} style={{fontFamily:isLngAr?'Tajawal':'inter'}}>
            {tNR("page_title_description")}
          </p>
        </div>
      </div>
      </div>
      <div className="mapContainerFirst"
      >
        <div className={styles.searchContainer}>
          <div className={styles.searchItemContainer}>
            <GlobalSearch
              loading={false}
              onChange={onSearchChange}
              onClick={onSearchClick}
              menu={[]}
              placeholder={tNR("ref_cle")}
            />
          </div>
          <div className={styles.filterContainer}>
            <FilterButton onChange={onChangeFilterButton} />
          </div>
        </div>
        <div className={filterItemsContainerStyle}>
          <div className={styles.selectElement}>
            <SelectForm
              label={tNR("prefecture")}
              placeholder={tNR("placeHolder")}
              options={getAllProvince.data?.page}
              loading={fetchprefectureListData.isFetching}
              setter={() => {}}
              onChange={onProvinceChange}
             // onChange={setchoosenProvince}
              search
              erasable
            />
          </div>
          <div className={styles.selectElement}>
            <SelectForm
              disabled={isEmptyString(inquiryCriteria.province)}
              label={tNR("commune")}
              placeholder={tNR("placeHolder")}
              options={getAllCommune.data?.page}
              onChange={onCommuneChange}
              lang={isLngAr}
              loading={getAllCommune.isFetching}
              onClick={onCommuneSelectClick}
              setter={() => {}}
              name={"commune"}
              // onClick={() => {}}
              // onChange={setchoosenCommune}
              search
              erasable
            />
          </div>
          <div className={styles.selectElement}>
            <SelectForm
              label={tNR("Procédure")}
              placeholder={tNR("placeHolder")}
              options={urbaOptions}
              loading={urbaOptions.isFetching}
              onChange={handleSelectChange}
              setter={() => {}}
              onClick={() => {}}
              errors={"errors"}
              name={""}
              multi
              search
            />
          </div>
        </div>
        {/* */}
        {loading ? (
          <div className="loading-spinner">Loading map...</div>
        ) : (
          <div>
            <div
          style={{
            position: "absolute",
            zIndex: "90000000000000000",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <FadeLoader
            color="#8BB2AA"
            height={31}
            width={12}
            margin={20}
            radius={11}
            loading={mergedEcodata.isFetching}
          />
        </div>
            <GeoMap center={[30.002517, -14.224784]} zoom={5}>
            <FilterControl onFilterChange={handleFilterChange} />
              <MarkersLayer
                folderData={Markerslist}
                prefecture={choosenProvince}
                commune={choosenCommune}
                eco={mergedEcodata}
                options={selectedFilterOptions}
                communeSearch={searchCle!=""?searchCle:""}
                selectedPermis={choosenPermis} 
                allcommunes={getAllCommune}
                lang={isLngAr}
              />
            </GeoMap>
          </div>
        )}
      </div>
    </div>
  );
};

export default Coverturegeo;
