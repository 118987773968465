import React from 'react';
import PropTypes from 'prop-types';
import {ConfigProvider, Select} from "antd";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import styles from "./table.module.css"
import useHandleTranslation from "../../hooks/useHandleTranslation";
import {useTranslation} from "react-i18next";
const Pagination = ({ page, size, pages, pageOptions,backGroundColor }) => {
    const {getDirX,getInverseDirX} =useHandleTranslation();
    const {t:tT}=useTranslation("table")
    const leftIcon=  <i className={`p-1 text-gray-400 fa-solid fa-chevron-${getDirX()} cursor-pointer hover:text-gray-700`} aria-hidden="true"/>;
    const rightIcon=  <i className={`p-1 text-gray-400 fa-solid fa-chevron-${getInverseDirX()} cursor-pointer hover:text-gray-700`} aria-hidden="true"/>
    return (
        <div className="px-3 mb-2 mt-4 py-2 flex  items-center justify-between" style={{ backgroundColor: backGroundColor || '#F7F9FC' }}>
            <div className="text-[#687182] responsive-web">
                {page*size+1}-{page*size+size} {tT("pagination_to")} {pages*size}
            </div>
            <div className={"flex gap-2 justify-center items-center responsive-web"}>
               <span className="text-[#687182]">{tT("pagination_select_rows")}</span>
                <ConfigProvider
                    theme={{
                        components: {
                            Select: {
                                selectorBg:"#F7F9FC",
                                optionSelectedColor:"#687182",
                            },
                            token: {
                                colorBorder:"#687182",
                                colorText:"#687182",
                                clearBg:"#687182"
                            }
                        },
                    }}
                >
                <Select
                    defaultValue={size}
                    onChange={(value) => {
                        pageOptions.setPageSize(value);
                    }}
                    options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                />
                </ConfigProvider>
            </div>
            <div className={"flex items-center gap-1 px-3"}>
                <PageButton
                    onClick={() => pageOptions.previousPage()}
                    disabled={!pageOptions.canPreviousPage}
                >
                    {leftIcon}
                </PageButton>
                <label>{page+1}</label>
                <label>/</label>
                <label >{pages}</label>
                <PageButton
                    onClick={() => pageOptions.nextPage()}
                    disabled={!pageOptions.canNextPage}
                >
                    {rightIcon}
                </PageButton>
            </div>
        </div>
    );
};


 const PageButton = ({ children, ...props }) => {
    return (
        <button
            type="button"
            className={styles.pageButton}
            {...props}
        >
            {children}
        </button>
    );
};

export default Pagination;
Pagination.propTypes = {
    page: PropTypes.number,
    size: PropTypes.number,
    pages: PropTypes.number,
    pageOptions: PropTypes.object,
};