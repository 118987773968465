import React, { useState, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Leaflet CSS

// Custom Icons (as defined earlier)
const LOIcon = L.divIcon({
  html: ` <svg width="34" height="38" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 23.8711 0.576173 26.6076 1.6192 29.1003C5.94533 40.912 21.1104 49 21.1104 49C21.1104 49 39.7389 39.0648 41.5928 25.1361C41.8599 23.7989 42 22.4158 42 21Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6509 24.7849L38.6318 24.8814L38.619 24.9789C37.8426 30.8763 33.4427 36.2795 28.6948 40.4277C26.3812 42.4489 24.1126 44.0616 22.42 45.1691C21.9283 45.4908 21.4872 45.7686 21.1104 46C20.8599 45.8462 20.5811 45.672 20.2779 45.4779C18.8615 44.5714 16.9286 43.2433 14.8719 41.5641C10.6804 38.142 6.31788 33.5375 4.4362 28.3436L4.41287 28.2792L4.38668 28.2159C3.4944 26.0601 3 23.691 3 21.1975C3 11.1473 11.0589 3 21 3C30.9411 3 39 11.1473 39 21.1975C39 22.4283 38.8796 23.6276 38.6509 24.7849Z" fill="#059669"/>
      <path d="M29 21C29 16.5817 25.4183 13 21 13C16.5817 13 13 16.5817 13 21C13 25.4183 16.5817 29 21 29C25.4183 29 29 25.4183 29 21Z" fill="white"/>
    </svg>`,
  className: "custom-icon",
  iconSize: [34, 38], // Adjust iconSize to match the SVG dimensions
});

const LOIcon1 = L.divIcon({
  html: ` <svg width="34" height="38" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 23.8711 0.576173 26.6076 1.6192 29.1003C5.94533 40.912 21.1104 49 21.1104 49C21.1104 49 39.7389 39.0648 41.5928 25.1361C41.8599 23.7989 42 22.4158 42 21Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M38.6509 24.7849L38.6318 24.8814L38.619 24.9789C37.8426 30.8763 33.4427 36.2795 28.6948 40.4277C26.3812 42.4489 24.1126 44.0616 22.42 45.1691C21.9283 45.4908 21.4872 45.7686 21.1104 46C20.8599 45.8462 20.5811 45.672 20.2779 45.4779C18.8615 44.5714 16.9286 43.2433 14.8719 41.5641C10.6804 38.142 6.31788 33.5375 4.4362 28.3436L4.41287 28.2792L4.38668 28.2159C3.4944 26.0601 3 23.691 3 21.1975C3 11.1473 11.0589 3 21 3C30.9411 3 39 11.1473 39 21.1975C39 22.4283 38.8796 23.6276 38.6509 24.7849Z" fill="#F59E0B"/>
      <path d="M29 21C29 16.5817 25.4183 13 21 13C16.5817 13 13 16.5817 13 21C13 25.4183 16.5817 29 21 29C25.4183 29 29 25.4183 29 21Z" fill="white"/>
    </svg>`,
  className: "custom-icon",
  iconSize: [34, 38],
});

// DossierMap Component
const DossierMap = ({ data }) => {
  const map = useMap(); // Access the map instance provided by GeoMap
  const markerClusterGroupRef = useRef(L.markerClusterGroup()); // Reference to the cluster group

  // Function to adjust the popup direction based on available space
  function adjustPopupDirection(marker, map) {
    const markerPixelPosition = map.latLngToContainerPoint(marker.getLatLng());
    const mapSize = map.getSize();
    const spaceAbove = markerPixelPosition.y;
    const spaceBelow = mapSize.y - markerPixelPosition.y;
    let popupAnchor;
    if (spaceBelow > spaceAbove) {
      popupAnchor = [0, 10];
    } else {
      popupAnchor = [0, -300];
    }
    return popupAnchor;
  }

  // Fetch detailed data from the API
  const fetchDetailedData = async (id) => {
    const apiUrl = "urba/rokhas/api-geo/search/popupInfo";

    const urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
    urlencoded.append("doid", "" + id);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch detailed data");
      }
      const detailedData = await response.json();
      return detailedData.data;
    } catch (error) {
      console.error("Error fetching detailed data:", error);
      return null;
    }
  };

  // Create marker based on data
  const createMarker = (item) => {
    const icons = item.categorie_desc === "Grands projets" ? LOIcon : LOIcon1;
    const marker = L.marker([item.latitude, item.longitude], {
      icon: icons, // Use the correct icon
    });

    // Handle mouseover to fetch detailed data
    let hoverTimeout;

    marker.on("mouseover", async () => {
      const popupAnchor = adjustPopupDirection(marker, map);
      const initialPopupContent = `<div class="popup-loading"></div>`;
      marker
        .bindPopup(initialPopupContent, {
          closeButton: false,
          autoPan: false,
          keepInView: true,
          closeOnClick: false,
          offset: popupAnchor,
          className: "custom-popup",
        })
        .openPopup();

      try {
        const detailedData = await fetchDetailedData(item.id);
        const popupContent = detailedData
          ? `<div class="popup">
              <div class="popup-content">
                <div class="data-title">
                  <div class="valeur-reference">${detailedData.reference}</div>
                </div>
                <hr style="border: 1px #D1D5DB;width: 75%;">
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Date de délivrance</span></div>
                  <div class="valeur">${detailedData.dateDelivrance}</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Numéro d'autorisation</span></div>
                  <div class="valeur">${detailedData.numAutorisation}</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Titre foncier</span></div>
                  <div class="valeur">${detailedData.tfonciers}</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Surface</span></div>
                  <div class="valeur">${detailedData.s_terrain} m&sup2;</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Nature de projet</span></div>
                  <div class="valeur">${detailedData.nature}</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Catégorie de projet</span></div>
                  <div class="valeur">${detailedData.categorie}</div>
                </div>
                <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Consistance</span></div>
                  <div class="valeur">${detailedData.consistence}</div>
                </div>
                <hr style="border: 1px #D1D5DB;width: 75%;">
                <div class="data-item">
                  <div><i class="fa-light fa-landmark" style="color: #373937;"></i></div>
                  <span class="label-adresses">${detailedData.prefecture}</span>
                </div>
                <div class="data-item">
                  <div><i class="fa-light fa-map" style="color: #373937;"></i></div>
                  <span class="label-adresses">${detailedData.commune}</span>
                </div>
                <div class="data-item">
                  <div><i class="fa-light fa-location-dot" style="color: #646568;"></i></div>
                  <span class="label-adresses">${detailedData.adresseProjet}</span>
                </div>
              </div>
            </div>`
          : `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`;
        marker.setPopupContent(popupContent);
      } catch (error) {
        console.error("Error fetching detailed data:", error);
        marker.setPopupContent(`<p>ID: ${item.id}</p><p>Error fetching data</p>`);
      }
    });

    // Mouse event handling
    document.addEventListener("mouseover", (event) => {
      const popupElement = document.querySelector(".leaflet-popup");
      if (popupElement && popupElement.contains(event.target)) {
        clearTimeout(hoverTimeout);
      }
    });

    document.addEventListener("mouseout", (event) => {
      const popupElement = document.querySelector(".leaflet-popup");
      if (popupElement && popupElement.contains(event.target)) {
        hoverTimeout = setTimeout(() => {
          marker.closePopup();
        }, 500);
      }
    });

    return marker;
  };

  useEffect(() => {
    if (data && data.length > 0) {
      // Function to add markers only if zoom level > 10
      const addMarkersIfZoomLevelAllows = () => {
        const zoomLevel = map.getZoom();
        if (zoomLevel > 10) {
          // Clear existing markers before adding new ones
          markerClusterGroupRef.current.clearLayers();

          // Add markers to the cluster group
          const maxMarkers = 5000; // Limit the number of markers
          const limitedData = data.slice(0, maxMarkers);
          limitedData.forEach((item) => {
            const marker = createMarker(item);
            markerClusterGroupRef.current.addLayer(marker);
          });

          // Add the marker cluster group to the map
          map.addLayer(markerClusterGroupRef.current);
        } else {
          // Remove the markers if zoom level <= 10
          markerClusterGroupRef.current.clearLayers();
        }
      };

      // Add markers when the map zoom level changes
      map.on("zoomend", addMarkersIfZoomLevelAllows);

      // Initial check to display markers based on the current zoom level
      addMarkersIfZoomLevelAllows();

      // Cleanup on unmount
      return () => {
        map.off("zoomend", addMarkersIfZoomLevelAllows);
        markerClusterGroupRef.current.clearLayers();
      };
    }
  }, [data, map]);

  return null; // No need to render anything, markers are added directly to the map
};

export default DossierMap;
