import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import React, { useEffect } from "react";
import './modal.css'
import classNames from "classnames";
const pdfViewer=({ isOpen,url, children,onClose })=>{
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const modalStyle=classNames({
        ["fixed top-0 w-2/3 p-6"]:true,
        ["hide-modal"]:!isOpen,
        ["show-modal"]:isOpen
    })
    const modalContainerStyle=classNames({
        ["modal-overlay"]:true,
        ["close-modal"]:!isOpen,
    })
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        if (isOpen && isMobile && url) {
            window.open(url, "_blank");
            onClose();
        }
    }, [isOpen, url, onClose]);

    if (isMobile && isOpen) {
        return null;
    }
    return(
        <>
                <div className={modalContainerStyle}>
                    <div className={modalStyle} style={{height: "90vh"}}>
                        <div className="flex flex-row-reverse w-auto bg-[#eeeeee]" style={{border:"1px solid #474747",borderBottom:"none"}}>
                        <div
                            className="bg-[#eeeeee] py-2 rounded-tr-sm rounded-tl-sm text-[#727272] hover:text-gray-700 hover:bg-[#c4c4c4]"
                            onClick={onClose}
                        >
                            <i className="fa-solid fa-xmark px-3 cursor-pointer"></i>
                        </div>
                         </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl={url || "/"}
                                plugins={[
                                    defaultLayoutPluginInstance,
                                ]}
                            />
                        </Worker>
                    </div>
                </div>
        </>

    )
}
export default pdfViewer;