import styles from "./input.module.css";
import globalStyles from "../../globalStyles.module.css";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useState} from "react";

const TextAreaForm = ({
    dir,
    important,
    label,
    placeholder,
    name,
    register,
    errors,
    cannotPaste,
    inputDir,
    rootClassName,
    disable,
    withoutLabel = false,
    containerClassName,
    stopTab,
    errorMessageDir,
    rows = 8 
}) => {
    const {t:tER} = useTranslation("formErrors");
    const {translateClassName} = useHandleTranslation();

    const textAreaFormStyles = classNames({
        [styles.inputFormItemContainer]: true,
        "!border-red-600": !!errors?.[name]?.message
    });

    const textAreaFormLabelStyles = classNames({
        [styles.inputFormLabel]: true,
        "!text-red-600": !!errors?.[name]?.message
    });

    const textAreaFormItemStyles = classNames({
        [styles.inputFormItem]: true,
        [styles.inputFormItemWithoutLabel]: withoutLabel,
        [styles.inputFormItemWithLabel]: !withoutLabel,
    });

    const errorMessageStyles = classNames({
        [globalStyles[translateClassName("regular")]]: true,
        [styles.errorMessage]: true,
        [styles.emptyErrorMessage]: !errors?.[name]?.message,
        [styles.notEmptyErrorMessage]: !!errors?.[name]?.message
    });

    const handlePaste = (e) => {
        if (cannotPaste) e.preventDefault();
    };

    return (
        <div className={`${styles.inputFormContainer} ${rootClassName}`} dir={dir}>
            {!withoutLabel && (
                <div className={textAreaFormLabelStyles}>
                    {label}
                    {important && <span className="text-red-500"> *</span>}
                </div>
            )}
            <div className={`${textAreaFormStyles} ${containerClassName}`} dir={inputDir}>
                <textarea
                    className={`${textAreaFormItemStyles} ${globalStyles[translateClassName("regular")]}`}
                    placeholder={placeholder}
                    {...register(name)}
                    onPaste={handlePaste}
                    dir={inputDir}
                    disabled={disable}
                    rows={rows}
                    {...stopTab && { tabIndex: "-1" }}
                />
            </div>
            <div dir={errorMessageDir} className={errorMessageStyles}>
                {tER(errors?.[name]?.message)}
            </div>
        </div>
    );
};

export default TextAreaForm;
