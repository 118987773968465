import HttpService from "../service/HttpService";
import {
  GET_ARTICLE_DATA_BY_CRITERIA,
  GET_ARTICLE_DATA_BY_ID,
  GET_ARTICLE_DATA_BY_MENU,
  GET_ARTICLE_EVIDENCE,
  GET_AUTO_COMPLETE_ACTIVITY,
  GET_AUTO_COMPLETE_ARTICLE,
  GET_CHECK_TOKEN_RESET_PASSWORD,
  GET_DYNAMIC_LIST,
  GET_FOLDER_DATA_BY_REFERENCE_PATH,
  GET_INQUIRIES_DATA,
  GET_RANKING_DATA,
  GET_RANKING_FILTER_CRITERIA,
  GET_RANKING_FILTER_MANNERS,
  GET_REF_ACTIVITIES_BY_CRITERIA,
  GET_REF_ACTIVITIES_COMMUNES,
  GET_REF_ACTIVITIES_DETAILS,
  GET_REF_ACTIVITIES_NATURES,
  GET_REF_ACTIVITIES_PROVINCES,
  GET_SIGNUP_CONFIRM,
  GET_SIGNUP_DEPARTEMENTS,
  GET_SIGNUP_PROCEDURES,
  GET_TAGS_DATA,
  POST_RESET_PASSWORD,
  POST_SEND_MAIL_RESET_PASSWORD,
  POST_SIGNUP,
  GET_REF_JURIDIQUE_BY_CRITERIA,
  GET_AUTO_COMPLETE_REF_JURIDIQUE,
  ADD_NEW_PROPOSATION_URL,
  FACT_REFECTION_URL,
  FACT_FACT_DOSSIER_URL,
  FACT_DEMOLITION_URL,
  FACT_RECEPTION_URL,
  FACT_AUTORISATIONEC_URL,
  FACT_AUTORISATIONAFP_URL,
  FACT_AUTORISATIONAN_URL,
  FACT_AUTORISATIONSD_URL,
  getPCPhDeployer,
  getDimLieu,
  getEcoDeployer,
  getListDIMREFREC,
  getCommunListData,
  getPrefectureList,
  SERACH_DOSSIER_CIN_URL,
  CHECK_IF_DOSSIER_EXISTE,
} from "./apiPath";
import {
  formatRankingMannersData,
  formatRankingData,
  formatDynamicList,
  formatSignUpProceduresData,
  formatArticleEvidenceData,
} from "./formatter";
import { CRITERIA, COOKIES } from "../util/constants";

const axios = HttpService.getAxiosClient();
const zohoApi = "zoho/api";


console.log("test")
export const getFolderDataByReference = async (reference, lng) => {
  const { data } = await axios.get(
    `${GET_FOLDER_DATA_BY_REFERENCE_PATH.path}/?reference=${reference}&lng=${lng}`
  );
  return data;
};
export const getArticleDataByCriteria = async (
  noPage,
  pageSize,
  search,
  tags,
  menu,
  lng = "fr"
) => {
  const { data } = await axios.get(
    `${GET_ARTICLE_DATA_BY_CRITERIA.path}/?noPage=${noPage}&pageSize=${pageSize}&search=${search}&tags=${tags}&menu=${menu}&lng=${lng}`
  );
  return data;
};
export const getTagsData = async (menu, lng = "fr") => {
  const {
    data: { data },
  } = await axios.get(`${GET_TAGS_DATA.path}/?menu=${menu}&lng=${lng}`);
  return data;
};
export const getAutoCompleteArticle = async (prefix = "", menu, lng = "fr") => {
  const { data } = await axios.get(
    `${GET_AUTO_COMPLETE_ARTICLE.path}/?prefix=${prefix}&menu=${menu}&lng=${lng}`
  );
  return data;
};
export const getActivityDataByCriteria = async (
  noPage,
  pageSize,
  search,
  type,
  nature,
  lng
) => {
  const { data } = await axios.get(
    `${GET_REF_ACTIVITIES_BY_CRITERIA.path}/?noPage=${noPage}&pageSize=${pageSize}&search=${search}&type=${type}&natureCode=${nature}&lng=${lng}`
  );
  return data;
};
export const getKarazalResourceProvince = async (lng) => {
  const { data } = await axios.get(
    `${GET_REF_ACTIVITIES_PROVINCES.path}?lng=${lng}`
  );
  return data;
};
export const getLocationData = async (
  bounds,
  cle,
  datedepotmin,
  datedepotmax,
  dateauthmin,
  dateauthmax
) => {
  const { _southWest, _northEast } = bounds;

  // Prepare the API URL and parameters
  const apiUrl = "urba/rokhas/api-geo/search/location";

  const params = new URLSearchParams({
    apiKey: "AB90G-BF903-W4EE1-Z6MQ9-7P22K",
    xmin: _southWest.lng.toString(),
    xmax: _northEast.lng.toString(),
    ymin: _southWest.lat.toString(),
    ymax: _northEast.lat.toString(),
    cle: cle,
    datedepotmin: datedepotmin,
    datedepotmax: datedepotmax,
    datedelivmin: dateauthmin,
    datedelivmax: dateauthmax,
  });

  // Make the request using axios
  try {
    const { data } = await axios.post(apiUrl, params);
    return data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Propagate error if the request fails
  }
};
export const getKarazalResourceCommune = async (province, lng, prop) => {
  const { data } = await axios.get(
    `${GET_REF_ACTIVITIES_COMMUNES.path}?province=${province}&lng=${lng}&prop=${prop}`
  );
  return data;
};

export const getActivityDetail = async (
  communeId = "",
  codeActivity = "",
  lng = "fr"
) => {
  const { data } = await axios.get(
    `${GET_REF_ACTIVITIES_DETAILS.path}?communeId=${communeId}&codeActivity=${codeActivity}&lng=${lng}`
  );
  return data;
};

export const getArticleDataById = async (articleId, lng) => {
  const { data } = await axios.get(
    `${GET_ARTICLE_DATA_BY_ID.path}/${articleId}?lng=${lng}`
  );
  return data;
};
export const getArticleDataByMenu = async (menu, lng) => {
  const { data } = await axios.get(
    `${GET_ARTICLE_DATA_BY_MENU.path}/${menu}?lng=${lng}`
  );
  return data;
};
export const getAutoCompleteActivity = async (
  prefix = "",
  type = "",
  lng = "fr"
) => {
  const { data } = await axios.get(
    `${GET_AUTO_COMPLETE_ACTIVITY.path}/?prefix=${prefix}&type=${type}&lng=${lng}`
  );
  return data;
};
export const getKarazalResourceActivityNatures = async (type, lng) => {
  const { data } = await axios.get(
    `${GET_REF_ACTIVITIES_NATURES.path}/?type=${type}&lng=${lng}`
  );
  return data;
};
export const getRankingFilterCriteria = async (criteria, lng) => {
  const {
    data: { data },
  } = await axios.get(
    `${GET_RANKING_FILTER_CRITERIA.path}/?criteria=${criteria}&lng=${lng}`
  );
  return data;
};

export const getRankingFilterManners = async (lng = "fr") => {
  const { data } = await axios.get(
    `${GET_RANKING_FILTER_MANNERS.path}?lng=${lng}`
  );
  return formatRankingMannersData(data);
};
export const getRankingData = async (
  page,
  size,
  search,
  periode,
  region,
  nbRanking,
  localite,
  sortField,
  asc,
  lng
) => { 
  const {
    data: { data },
  } = await axios.get(
    `${GET_RANKING_DATA.path}?noPage=${page}&pageSize=${size}&search=${search}&periode=${periode}&region=${region}&ranking=${nbRanking}&loacalite=${localite}&sortField=${sortField}&asc=${asc}&lng=${lng}`
  );
  return formatRankingData(data);
};
export const getInquiriesData = async (
  page,
  size,     
  search,
  commune,
  reference,
  province,
  lng
) => {
  const { data } = await axios.get(
    `${GET_INQUIRIES_DATA.path}?noPage=${page}&pageSize=${size}&search=${search}&commune=${commune}&province=${province}&reference=${reference}&lng=${lng}`
  );
  return data;
};
export const getDynamicList = async (listName) => {
  const formData = {
    apiKey: GET_DYNAMIC_LIST.apiKey,
    userId: GET_DYNAMIC_LIST.userId,
    lvName: listName,
  };
  const formEncodedData = new URLSearchParams(formData).toString();
  let { data } = await axios.post(GET_DYNAMIC_LIST.path, formEncodedData);
  return formatDynamicList(data);
};
export const getKarazalResourceDepartments = async (
  lng = "",
  profile = "",
  domaine = "",
  search = ""
) => {
  const { data } = await axios.get(
    `${GET_SIGNUP_DEPARTEMENTS.path}?lng=${lng}&profile=${profile}&domaine=${domaine}&search=${search}`
  );
  return data;
};
export const getSignUpProcedures = async (lng = "", profile = "") => {
  const { data } = await axios.get(
    `${GET_SIGNUP_PROCEDURES.path}?lng=${lng}&profile=${profile}`
  );
  return formatSignUpProceduresData(data);
};
export const sendSignUpData = async (data, lng) => {
  const { data: result } = await axios.post(
    `${POST_SIGNUP.path}?lng=${lng}`,
    data
  );
  return result;
};
export const getSignUpConfirmation = async (object) => {
  const { data: result } = await axios.get(
    `${GET_SIGNUP_CONFIRM.path}?object=${object}`
  );
  return result;
};
export const sendEmailResetPassword = async (email) => {
  const { data } = await axios.post(
    `${POST_SEND_MAIL_RESET_PASSWORD.path}?email=${email}`
  );
  return data;
};
export const sendResetPassword = async (token, password, repassword) => {
  const { data } = await axios.post(
    `${POST_RESET_PASSWORD.path}?token=${token}&password=${password}&repassword=${repassword}`
  );
  return data;
};
export const getCheckTokenResetPassword = async (token) => {
  const { data } = await axios.get(
    `${GET_CHECK_TOKEN_RESET_PASSWORD.path}?token=${token}`
  );
  return data;
};
export const getArticleEvidence = async (lng = "fr") => {
  const { data } = await axios.get(`${GET_ARTICLE_EVIDENCE.path}/?lng=${lng}`);
  return data;
};
export const getRefJuridiqueDataByCriteria = async (
  noPage,
  pageSize,
  search,
  type,
  category,
  lng
) => {
  const { data } = await axios.get(
    `${GET_REF_JURIDIQUE_BY_CRITERIA.path}/?noPage=${noPage}&pageSize=${pageSize}&search=${search}&type=${type}&category=${category}&lng=${lng}`
  );
  return data;
};

export const getAutoCompleteRefJuridique = async (
  prefix = "",
  category = "",
  type = "",
  lng = "fr"
) => {
  const { data } = await axios.get(
    `${GET_AUTO_COMPLETE_REF_JURIDIQUE.path}/?prefix=${prefix}&category=${category}&type=${type}&lng=${lng}`
  );
  return data;
};


export const getCommuneListData = async (page = 1) => {
  try {
   
       const response = await axios.get(`${getCommunListData.path}`);


    return response.data;
  } catch (error) {
    console.error("Error fetching permits data:", error.message);
    throw error; // Allows React Query to handle the error
  }
};
export const getPrefectureListData = async (page = 1) => {
  try {
    
   const response = await axios.get(`${getPrefectureList.path}`);
    const prefectureList = response.data.data.map((item) => item.prefecture);
    return prefectureList;
  } catch (error) {
    console.error("Error fetching prefecture data:", error.message);
    throw error;
  }
};
export const getCOMMUNEListNames = async (page = 1, prefecture) => {
  console.log("prefecture++++", prefecture); // Should log the `prefecture` value correctly
  try {
  const response = await axios.get(`${getDimLieu.path}/?prefecture=${prefecture}`);
    const prefectureList = response.data.data.map(
      (item) => item.ARRONDISSEMENT
    );
    console.error("test result of function", prefectureList);
    return prefectureList;
  } catch (error) {
    console.error("Error fetching prefecture data:", error.message);
    throw error;
  }
};

export const fetchMergedDataEco = async (page, prefecture) => {
  try {
    const response1 = await axios.get(
      `${getDimLieu.path}/?prefecture=${prefecture}`
    );


    const response2 = await axios.get(
      `${getEcoDeployer.path}/?prefecture=${prefecture}`
    );

    const response3 = await axios.get(
      `${getPCPhDeployer.path}/?prefecture=${prefecture}`
    );

   
    const response4 = await axios.get(
      `${getListDIMREFREC.path}/?prefecture=${prefecture}`
    );
    const api2Map = response2.data.data.reduce((acc, item) => {
      const key1 = item.ARRONDISSEMENT;
      const key2 = item.ARRONDISSEMENT_FR;

      acc[key1] = item;
      acc[key2] = item;

      return acc;
    }, {});

    const api3Map = response3.data.data.reduce((acc, item) => {
      acc[item.ID_LIEU] = item;
      return acc;
    }, {});
    const api4Map = response4.data.data.reduce((acc, item) => {
      const key = item.id_lieu;
      if (key) {
        acc[key] = item;
      } else {
        console.warn("Undefined id_lieu found for item:", item);
      }

      return acc;
    }, {});
    const mergedData = response1.data.data.map((item) => {
      const api2Info =
        api2Map[item.ARRONDISSEMENT] || api2Map[item.ARRONDISSEMENT_FR] || {};
      const api3Info = api3Map[item.ID_LIEU] || {};
      const api4Info = api4Map[item.ID_LIEU] || {};

      const mergedItem = {
        ...item,
        ...api2Info,
        ...api3Info,
        ...api4Info,
      };

      return mergedItem;
    });

    return mergedData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};



export const addNewProposation = async (proposationData) => {
  console.log("proposationData==", proposationData);
  console.log("URL:", ADD_NEW_PROPOSATION_URL.path);
  const { data } = await axios.post(
    ADD_NEW_PROPOSATION_URL.path,
    proposationData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  console.log("data==", data);
  return;
};

const fetchListDataPc = async (endpoint, page, criteria) => {
  try {
    const response = await axios.get(endpoint, {
      params: getParams(page, criteria),
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error.message);
    throw error;
  }
};
export const getAllDossierByCin = async (maitreOCin, searchTerm, page) => {
  const { data } = await axios.get(
    `${SERACH_DOSSIER_CIN_URL.path}/?maitreOCin=${maitreOCin}&searchTerm=${searchTerm}`
  );
  return data;
};



export const checkIfCinExiste= async (maitreOCin) => {
    const {data} = await axios.get(
        `${CHECK_IF_DOSSIER_EXISTE.path}/?maitreOCin=${maitreOCin}`
    );
    return data;
};


