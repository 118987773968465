import React, { useState, useEffect} from 'react';
import Btn from '../components/button/Btn';
import Icon from '../components/util/Icon';
import {Menu as MenuMini, Popover} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import { LogoAr, LogoFr } from '../asset/images/svgImages';
import i18n from '../i18n';
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import useHandleClassName from "../hooks/useHandleClassName";
import {ConfigProvider} from "antd";
import {isEmptyString, redirect, toLogin, toSignUp} from "../util/functionsUtil";
import {HOME_URL, LOGIN_URL, SIGN_UP_URL} from "../util/constants";
import useHandleTranslation from "../hooks/useHandleTranslation";

const login=()=>{
    redirect( LOGIN_URL);
}


const Header = (props) => {
    const [menuEnabled, setMenuEnabled] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const {i18n,t}=useTranslation("header");
    const {translate}=useHandleClassName();
    const iconsMenu = [
        [['eye', 'timeline',{ white: 'ribatis_w_system', black: 'ribatis_b_system' }],
            ['location-dot', 'browsers', 'road'],
            [{ white: 'karaz_w_system', black: 'karaz_b_system' },'gear', 'shield-check', 'database']
        ],
        [['book', 'laptop-medical', 'circle-plus', 'files'],
            ['magnifying-glass', 'file-exclamation', 'list-check', 'face-meh-blank'],
            ['map-location', 'arrow-up-a-z', 'database', 'chart-column']],
        [['book', 'circle-info', 'laptop-arrow-down'],
            ['presentation-screen', 'video', 'podcast'],
            ['message-question', 'hands-holding-heart', 'users']],
        [['calendar-day', 'pen-square', 'newspaper'],
            ['pennant', 'map-marker-edit', 'pen-clip'],
            ['arrows-rotate', 'file-arrow-up', 'timer']],
    ];





    useEffect(() => {
        document.body.style.overflow = showMenu ? "hidden" : "auto";
    }, [showMenu]);
    const menuItems = props.data?.menuItems?.map(
        (menuItems, indexMenuItem) => {
            return {
                menuItemsLabel: menuItems.title,
                menuItems: menuItems.showMenu.filter(item=>item.title!=="-").map(
                    (menuItemToShow, indexMenuItemToShow) => {
                        return {
                            label: menuItemToShow.title,
                            key: `item-${indexMenuItemToShow}`,
                            children: menuItemToShow.columnItems.filter(item=>!isEmptyString(item.Link)).map((cItem, indexItem) => {
                                return {
                                    label: <a href={cItem.Link}>{cItem.Label}</a>,
                                    icon: <Icon iconType='light' iconName={iconsMenu[indexMenuItem][indexMenuItemToShow][indexItem]?.black || iconsMenu[indexMenuItem][indexMenuItemToShow][indexItem]} />,
                                    link:cItem.Link,
                                    hoverMessage:cItem.hover_message,
                                    key: `nested-item-${indexMenuItem}-${indexMenuItemToShow}-${indexItem}`,
                                };
                            })
                        };
                    }
                )
            };
        }
    );

    const menuItemsImg = ["hand-holding-box", "rokhas_system", "folder-bookmark", "newspaper"];
    const navigate = useNavigate();

    return (
        <>
            {/* PART :: HEADER ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ WEB CASE */}
            <div className={`header ${translate("medium")}`}>
                {/* PART :: BURGER ++++++++++++++ LOGO */}
                <div className='header-container-logo'
                     style={i18n.language === "ar" ? {marginRight: '10px'} : {marginLeft: '10px'}}>
          <span id="menu-burger" className={(showMenu ? 'menu-burger-clicked ' : '') + 'header-medium header-mini'}
                onClick={() => {
                    setShowMenu(!showMenu);
                    showMenu ?
                        document.querySelector(".cancel-mini-menu").classList.remove("cancel-actived") || document.querySelector(".body-content").classList.remove("body-content-cancel") :
                        document.querySelector(".cancel-mini-menu").classList.add("cancel-actived") || document.querySelector(".body-content").classList.add("body-content-cancel");
                }}>
            <span></span>
            <span></span>
            <span></span>
          </span>
                    <a className="cursor-pointer" onClick={()=>navigate("/")}>
                        {i18n.language === "fr" ? <LogoFr/> : (i18n.language === "ar" ? <LogoAr/> : (""))}
                    </a>
                </div>

                {/* PART :: MENU ++++++++++++++++++++++ */}
                <Menu data={props.data?.menuItems} icons={iconsMenu} />

                {/* PART :: USER ACCOUNT +++++++ LANGUAGE */}
                <HeaderUser data={props.data}/>
            </div>

            {/* PART :: MENU ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ MOBILE CASE */}
            {showMenu &&
                <div className="header-mini-container">
                    {/* PART :: TOP MENU +++++++ TO CHOICE SECTIONS ( ITEMS ) */}
                    <div className="header-mini-menu-top">
                        {menuItems.map((menuItem, index) => <React.Fragment key={index}>
              <span onClick={() => {
                  setMenuEnabled(index)
              }}
                    className={(menuEnabled === index ? "header-mini-menu-enabled " : " ") + "header-mini-menu"}>
                <Icon iconType="light" iconName={menuItemsImg[index]}/>
                <span>{" " + index === menuEnabled ? menuItem.menuItemsLabel : ""}</span>
              </span>
                        </React.Fragment>)}
                    </div>

                    {/* PART :: BODY MENU ++++++++++++++++++++++++++++++++++++ */}
                    <MenuMini defaultOpenKeys={['item-0']} mode="inline" items={menuItems[menuEnabled].menuItems}/>

                    {/* PART :: BOTTOM MENU ++++++++++++++ INFORMATION OF THE ENTREPRISE */}
                    <div className='header-mini-menu-bottom'>
                        <div className='header-mini-menu-bottom-title'>{t("needHelp")}</div>
                        <div className='header-mini-menu-bottom-container'>
                            <div className='header-mini-menu-bottom-list'>
                                <div className="flex-center" style={{justifyContent: "space-between", width: "100%"}}>
                                    <div style={{fontSize: "12px"}}>
                                        <i style={{padding: "0 5px"}}><Icon iconType='light' iconName='envelope'
                                                                            className="blue-color"/></i>
                                        <span>{t("mail")}</span>
                                    </div>
                                    <div style={{fontSize: "12px"}}>
                                        <i style={{padding: "0 5px"}}><Icon iconType='light' iconName='phone'/></i>
                                        <span dir={"ltr"}>{t("phone")}</span>
                                    </div>
                                </div>
                                <div className="flex-center" style={{justifyContent: "space-between", width: "100%"}}>
                                    <div style={{fontSize: "12px"}}>
                                        <i style={{padding: "0 5px"}}><Icon iconType='light' iconName='book-open'/></i>
                                        <span>{t("FAQ")}</span>
                                    </div>
                                    <div style={{fontSize: "10px"}}>
                                        <span style={{fontSize: "10px"}}>{t("workTime")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
const HeaderUser = (props) => {
    const {isLngFr,isLngAr}=useHandleTranslation();
    const {t:th}=useTranslation("header")
    const [language, setLanguage] = useState('Français');
    const {pathname} = useLocation()
    const navigate=useNavigate();
    const signup=()=>{
        navigate(SIGN_UP_URL);
    }
    const handleLanguageChange = (language) => {
        if(pathname!=="/") redirect(HOME_URL);
        i18n.changeLanguage(language.code);
        setLanguage(language.label);
        localStorage.setItem('selectedLanguage',language.code)
        document.body.dir=language.dir;
        document.body.lang=language.code;
    };
    const languageOptions = [
        { code: 'fr', label: 'Français',dir:"ltr"},
        { code: 'ar', label: 'العربية',dir:"rtl"},
    ];
    const signOptions = [
        { label: th("connecter"), onclick: ()=>toLogin() },
        { label: th("creerCompte"), onclick:()=>signup() }
    ];


    useEffect(()=>{
        setLanguage(languageOptions.find((obj)=>(i18n.language===obj.code)).label);
    },[])


    const choiceLanguageContainerStyles=classNames({
        "choice-language-list-container": true,
        "choice-language-list-container-right": isLngAr,
        "choice-language-list-container-left": isLngFr,
    })
    const signMenuContainerStyles=classNames({
        "sign-menu-list-container": true,
        "sign-menu-list-container-right": isLngAr,
        "sign-menu-list-container-left": isLngFr,
    })
    return (
        <div className='header-user'>
            <div className='sign-menu-container'>
                <Btn color="#0369A1" backgroundColor="#fff" borderColor="#fff">
                     <span className='header-mini header-lang-mini'>
                                 <i className="fa-solid fa-user text-[#0369A1]"/>
                             </span>
                </Btn>
                {<div className={signMenuContainerStyles}>
                    <ul>
                        {signOptions.map((option,index) => (
                            <li key={index} onClick={option.onclick}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>}
            </div>
            <span className="header-mini header-mini-pipe"></span>
            <div className="header-medium header-large header-buttons-account">
                <Btn className="white-button" onClick={login} color="#0369A1" backgroundColor="#fff" borderColor="#0369A1" content={props.data?.buttons[0]}/>
                <Btn className="blue-button" onClick={signup} color="#fff" backgroundColor="#0369A1" content={props.data?.buttons[1]} />
            </div>
           <div className='choice-language-container'>
                <Btn color="#0369A1" backgroundColor="#fff" borderColor="#fff"
                     content={
                         <>
                             <span  className='header-medium header-large'>{language}
                             <i className="rotate-awsome-icon fa-solid fa-caret-down hover:cursor-pointer flex items-center duration-300 ease-in-out"/>
                             </span>
                             <span className='header-mini header-lang-mini'>
                                 {i18n.language.toUpperCase()}
                                 <i className="rotate-awsome-icon fa-solid fa-chevron-down hover:cursor-pointer flex items-center duration-300 ease-in-out"/>
                             </span>
                         </>
                     }/>
                {<div className={choiceLanguageContainerStyles}>
                    <ul>
                    {languageOptions.map((option) => (
                            <li key={option.code}  className={"regular-"+option.code} onClick={() => handleLanguageChange(option)}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>}
            </div>
        </div>
    ) ;
}

const Menu=(props)=> {
    const navigate=useNavigate();

    console.log("props.data : ",props.data);

    const [cancelHover,setCancelHover] = useState(false);
    const [menuHoveredIndex,setIsMenuHovered] = useState(null);
    const handleMenuItemClick = (link) => {
        navigate(link);
        setIsMenuHovered(null);
    }
    const menuToShowStyle=(index)=>classNames({
        "header-menu-to-show": true,
        "menu-to-show": true,
        "menu-to-show-hover":menuHoveredIndex===index
    })
    const menuToShowIconStyle=(index)=>classNames({
        "menu-to-show": true,
        "menu-to-show-hover":menuHoveredIndex===index
    })
    const onHover=(index)=>{
        setIsMenuHovered(index);
    }
    const onLeaveHover=()=>{
        setIsMenuHovered(null);
    }
    return <div className='header-menu header-large'>
        {
            props.data?.map(
                (item,index1) => <React.Fragment key={index1}>
                    <div className='header-menu-to-hover' onMouseOver={()=>onHover(index1)} onMouseLeave={()=>onLeaveHover()}>
                        <div className="header-menu-items" dir={"ltr"}> {item.title} <Icon iconType="solid" iconName={"caret-down"}/> </div>
                        <Icon iconType='solid' iconName='triangle' className={menuToShowIconStyle(index1)}/>
                        <div style={i18n.language==="ar"?{left:"0"}:{left:"100px"}} className={menuToShowStyle(index1)}>
                            {
                                item.showMenu?.filter((column)=>column.title !=="-" ).map(
                                    (column, index2) => <React.Fragment key={index2}>
                                        <div>
                                            <div className="header-categorie-name">
                                                <span>{column.title?.toUpperCase()}</span>
                                            </div>
                                            <div className='header-menu-column'>
                                                {
                                                    column.columnItems.filter(menu=>menu.Link.length !==0).map( (menu , index3) => {
                                                           return <React.Fragment key={index3}>
                                                                {menu.Link.length !== 0 &&
                                                                    <a  className="header-item-list-item"
                                                                       onClick={() => handleMenuItemClick(menu.Link)}>
                                                                        <Icon iconType='light'
                                                                              iconName={props.icons[index1][index2][index3]?.white || props.icons[index1][index2][index3]}/>
                                                                        <span>{menu.Label}</span>
                                                                    </a>}
                                                                {menu.Link.length === 0 &&
                                                                    <ConfigProvider
                                                                        theme={{
                                                                            token: {
                                                                                colorBgElevated: "#94a3b8"
                                                                            }
                                                                        }}>
                                                                        <Popover placement="bottom"
                                                                                 content={menu.hover_message}
                                                                                 trigger="hover">
                                                                            <div className="header-item-list-item">
                                                                                <Icon iconType='light'
                                                                                      iconName={props.icons[index1][index2][index3]?.white || props.icons[index1][index2][index3]}/>
                                                                                <span
                                                                                    className="!text-slate-400" onClick={cancelHover}>{menu.Label}</span>
                                                                            </div>
                                                                        </Popover>
                                                                    </ConfigProvider>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    </div>
}
export default Header