import React, { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Calculate centroid of polygon coordinates
const calculateCentroid = (coordinates) => {
  let x = 0;
  let y = 0;
  let z = 0;

  coordinates.forEach((coord) => {
    const latitude = (coord[1] * Math.PI) / 180;
    const longitude = (coord[0] * Math.PI) / 180;
    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  });

  const total = coordinates.length;
  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return [
    (centralLongitude * 180) / Math.PI,
    (centralLatitude * 180) / Math.PI,
  ];
};

// Calculate dynamic radius for markers
const calculateRadius = (value, maxValue) => {
  const minRadius = 400;
  const maxRadius = 550;
  const normalizedRadius =
    minRadius + (value / maxValue) * (maxRadius - minRadius);
  return Math.max(normalizedRadius, maxRadius / 2);
};

// Format numbers (e.g., 1000 -> "1k")
const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(0) + "k";
  }
  return num.toString();
};

// Create custom icon for markers
const createIcon = (item, radius) => {
  const size = Math.max(radius / 10, 20);
  const iconHtml = `  
    <div class="circle-icon"
      style="
        width: ${size - 8}px;
        height: ${size - 8}px;
        border-radius: 50%;
        background-color: rgba(255, 0, 0, 0.6);
        border: 1px solid white;
        box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 15px;">
      ${formatNumber(item.nbDossier)}
    </div>
  `;
  return L.divIcon({
    html: iconHtml,
    className: "custom-icon",
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
  });
};

const RegionMap = ({ data, regionpub, onZoomChange }) => {
  const map = useMap();
  const [geoJsonLayer, setGeoJsonLayer] = useState(null);
console.log("geoJsonLayer",geoJsonLayer)

  useEffect(() => {
    if (!map || !data || data.length === 0 || !regionpub) return;

    const maxValue = Math.max(...data.map((item) => item.nbDossier));

    // Remove existing GeoJSON and circle layers
    map.eachLayer((layer) => {
      if (layer instanceof L.GeoJSON || layer instanceof L.CircleMarker || layer instanceof L.Marker) {
        map.removeLayer(layer);
      }
    });

    // Create and add new GeoJSON layer
    const newGeoJsonLayer = L.geoJSON(regionpub, {
      style: (feature) => {
        const regionData = data.find(
          (item) => item.region === feature.properties.NAME
        );
        const fillColor = feature.properties.color || "#3388ff";
        const radius = regionData
          ? calculateRadius(regionData.nbDossier, maxValue)
          : 10;
        return {
          color: fillColor,
          weight: 3,
          opacity: 0,
          fillOpacity: 0.5,
          fillColor: fillColor,
          radius: radius,
        };
      },
      onEachFeature: (feature, layer) => {
        const regionData = data.find(
          (item) => item.region === feature.properties.NAME
        );
        if (!regionData) return;

        const coordinates = feature.geometry.coordinates[0];
        const [longitude, latitude] = calculateCentroid(coordinates);
        const radius = calculateRadius(regionData.nbDossier, maxValue);
        const icon = createIcon(regionData, radius);
        const buttonId = `zoom-button-${feature.properties.NAME.replace(
          /\s+/g,
          "-"
        )}`;
        const popupContent = `  
          <div class="popup">
            <div class="popup-content-reg">
              <div class="data-itemReg" id="${buttonId}">
                <div class="valeurReg">${feature.properties.NAME}</div>
              </div>
              <div class="zoom-button"></div>
            </div>
          </div>
        `;

        const marker = L.marker([latitude, longitude], { icon })
          .bindPopup(popupContent)
          .addTo(map);

        // Add zoom functionality
        layer.on("click", function (e) {
          if (map.getZoom() < 10) {
            this.bindPopup(popupContent, {
              autoPan: false,
              keepInView: true,
              closeOnClick: false,
              className: "custom-popup",
            });

            this.openPopup();

            setTimeout(() => {
              const zoomButton = document.getElementById(buttonId);
              if (zoomButton) {
                const zoomHandler = () => {
                  const mouseLatLng = e.latlng;
                  zoomToMousePointer(mouseLatLng);
                  zoomButton.style.display = "none";
                  zoomButton.removeEventListener("click", zoomHandler);
                  this.closePopup();
                };

                zoomButton.addEventListener("click", zoomHandler);
              }
            }, 100);
          } else {
            this.closePopup();
            map.removeLayer(this);
          }
        });
        layer.bindPopup(popupContent);
      },
    });

    newGeoJsonLayer.addTo(map);
    setGeoJsonLayer(newGeoJsonLayer);

    // Handle zoom and movement
    const handleZoom = () => {
      const zoomLevel = map.getZoom();
      const bounds = map.getBounds();
      if (zoomLevel > 10) {
        onZoomChange(bounds);
        map.removeLayer(newGeoJsonLayer); // Remove previous layer when zoom > 10
      } else if (!map.hasLayer(newGeoJsonLayer)) {
        newGeoJsonLayer.addTo(map);
      }
    };

    map.on("zoomend", handleZoom);
    map.on("moveend", handleZoom);

    return () => {
      map.off("zoomend", handleZoom);
      if (newGeoJsonLayer) {
        map.removeLayer(newGeoJsonLayer);
      }
    };
  }, [map, data, regionpub, onZoomChange]);

  const zoomToMousePointer = (mouseLatLng) => {
    const zoomLevel = 11;
    map.setView(mouseLatLng, zoomLevel);
  };

  return null;
};

export default RegionMap;
