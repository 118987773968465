import styles from "./input.module.css"
import globalStyles from "../../globalStyles.module.css"
import useHandleTranslation from "../../hooks/useHandleTranslation";
import classNames from "classnames";
import {autoCompleteBold} from "../../util/functionsUtil";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import useHandleClassName from "../../hooks/useHandleClassName";
import {useTranslation} from "react-i18next";
import useComponentVisible from "../../hooks/useComponentVisible";
import {Tooltip } from 'antd';


const InputForm=({dir,important,label,placeholder,name,errors,inputDir,info,...props})=>{
    const {translate}=useHandleClassName();
    const {t:tER}=useTranslation("formErrors");
    const inputRef=useRef(null);
    const {translateClassName}=useHandleTranslation()
    const [searchInput,setSearchInput]=useState("")
    const {ref,isOpen:isMenuOpen,setOpenStatus:setMenuStatus}=useComponentVisible();
    const [selectedInput,setSelectedInput]=useState(null);
    const [isSelectedInput,setSelectedInputStatus]=useState(false);

    const inputFormStyles=classNames({
        [styles.inputFormItemContainer]:true,
        "!border-red-600": !!errors?.[name]?.message
    })
    const inputFormLabelStyles=classNames({
        [styles.inputFormLabel]:true,
        "!text-red-600": !!errors?.[name]?.message
    })
    const menuClass=classNames({
        [styles.searchMenu]:true,
        [globalStyles.notDisplay]:!isMenuOpen,
    })
    const onSearchMenuClick=(index)=>{
        setMenuStatus(false);
        setSearchInput(null);
        setSelectedInput(props.menu[index]);
        setSelectedInputStatus(true);
        props.onMenuClick && props.onMenuClick(props.menu[index]);

    }
    const onSearchCancel=()=>{
        props.onMenuClick && props.onMenuClick(null);
        setSelectedInputStatus(false);
    }
    const onSearchChange=(e)=>{
        if (e.target.value.length<3) setMenuStatus(false);
        else setMenuStatus(!!(props.menu && props.menu?.length !== 0));
        setSearchInput(e.target.value);
        props.onChange && props.onChange(e.target.value);
    }
    const selectedTextStyles=classNames({
        [globalStyles.notDisplayV2]:!isSelectedInput,
        "transition-all duration-100":true,
        [`${globalStyles.show} ${styles.inputFormItem}`]:isSelectedInput,
    })
    useEffect(() => {
        setMenuStatus(searchInput && props.menu && props.menu?.length !== 0)
    }, [props.menu]);

    return (
        <div className={styles.inputFormContainer} dir={dir} ref={ref}>
            <div className={inputFormLabelStyles}>
                {label}
                {important && <span className="text-red-500"> *</span>}
            </div>
            <div className={inputFormStyles} dir={inputDir}>
                {!isSelectedInput && <input className={`${styles.inputFormItem} ${globalStyles[translateClassName("regular")]}`}
                       placeholder={placeholder} ref={inputRef} onChange={(e) => onSearchChange(e)}
                />}
                <span  className={selectedTextStyles} >{selectedInput}</span>
                {props.loading && <div className="pt-2 px-2"><i className="fa-solid fa-spinner-third fa-spin !text-[#6B7280]"/></div>}
                { !props.loading &&  <div onClick={onSearchCancel} className="pt-2 px-2 cursor-pointer">
                    {isSelectedInput && <i className="fa-solid fa-xmark !text-[#6B7280]"/>}
                    {!isSelectedInput && info && (
                        <Tooltip color="#0369A1" title={info} >
                            <i className="fa-duotone fa-circle-info !text-[#6B7280]"></i>
                        </Tooltip>)}
                </div>
                }
            </div>
            <div className={`${menuClass} ${translate("regular")}`}>
            {searchInput && props.menu && props.menu.map((item, index) => <div key={index} className={styles.searchMenuItem}
                                                      onClick={() => onSearchMenuClick(index)}>
                    {autoCompleteBold(searchInput, item)
                        .map((element, key) => (element.isBold ? (
                            <b key={key}>{element.label}</b>) : element.label))}</div>)}
            </div>
            <div className={styles.errorMessage}>{tER(errors?.[name]?.message)}</div>

        </div>
    )
}
export default InputForm;