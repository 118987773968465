
export const GET_FOLDER_DATA_BY_REFERENCE_PATH= {
    path:"/front/karazal/kas/api-ps/v2/ws-search/folderByRef"
}
export const GET_ARTICLE_DATA_BY_CRITERIA={
    path: "/front/karazal/kas/api-el/art/articles"
}
export const GET_TAGS_DATA={
    path: "/front/karazal/kas/api-el/art/tags"
}
export const GET_AUTO_COMPLETE_ARTICLE={
    path: "/front/karazal/kas/api-el/art/search/completion"
}
export const GET_REF_ACTIVITIES_BY_CRITERIA={
    path: "/front/karazal/kas/api-el/refActivities/data"
}
export const GET_KARAZ_ACTION={
    path:"/front/karazal/kas/FeedBackAction"

}

export const GET_ARTICLE_DATA_BY_ID={
    path: "/front/karazal/kas/api-el/art/article"
}
export const GET_ARTICLE_DATA_BY_MENU={
    path: "/front/karazal/kas/api-el/art/single-article"
}
export const GET_AUTO_COMPLETE_ACTIVITY={
    path: "/front/karazal/kas/api-el/refActivities/search/completion"
}
export const GET_RANKING_FILTER_CRITERIA={
    path: "/front/karazal/kas/api-el/ranking/filterCriteria"
}
export const GET_RANKING_DATA={
    path: "/front/karazal/kas/api-el/ranking/data"
}
export const GET_RANKING_FILTER_MANNERS={
    path: "/front/karazal/kas/api-el/ranking/manners"
}
export const GET_INQUIRIES_DATA={
    path: "/front/karazal/kas/api-ps/ck-search/inquiries"
}
export const GET_REF_ACTIVITIES_NATURES={
    path: "/front/karazal/kas/api-ps/ck-search/natures"
}
export const GET_REF_ACTIVITIES_PROVINCES={
    path: "/front/karazal/kas/api-ps/ck-search/provinces"
}
export const GET_REF_ACTIVITIES_COMMUNES={
    path: "/front/karazal/kas/api-ps/ck-search/communes"
}
export const GET_REF_ACTIVITIES_DETAILS={
    path: "/front/karazal/kas/api-ps/ak-search/activity/details"
}
export const GET_SIGNUP_DEPARTEMENTS={
    path: "/front/karazal/kas/api-ps/ck-search/departements"
}
export const GET_SIGNUP_PROCEDURES={
    path:"front/karazal/kas/api-ps/ak-search/signup/procedures"
}
export const POST_SIGNUP={
    path:"front/karazal/kas/api-ps/ak-search/signup"
}
export const GET_DYNAMIC_LIST={
    path: "/front/karazal/ow-rs/search/dynamicList",
    apiKey:"AB90G-BH903-W4EE1-Z66Q9-7822K",
    userId:"444110"
}
export const GET_SIGNUP_CONFIRM={
    path: "/front/karazal/kas/api-ps/ak-search/signup/confirm"
}
export const POST_SEND_MAIL_RESET_PASSWORD={
    path:"/front/karazal/kas/api-ps/login/resetPassword/confirmEmail"
}
export const GET_CHECK_TOKEN_RESET_PASSWORD={
    path:"/front/karazal/kas/api-ps/login/resetPassword/checkToken"
}
export const POST_RESET_PASSWORD={
    path:"/front/karazal/kas/api-ps/login/resetPassword"
}
export const GET_ARTICLE_EVIDENCE={
    path:"/front/karazal/kas/api-el/art/articleEvidence"
}
export const GET_REF_JURIDIQUE_BY_CRITERIA={
    path: "/front/karazal/kas/api-el/refJuridique/data"
}

export const GET_AUTO_COMPLETE_REF_JURIDIQUE={
    path: "/front/karazal/kas/api-el/refJuridique/search/completion"
}

export const ADD_NEW_PROPOSATION_URL={
    path: "/front/karazal/kas/api-ps/ck-search/addPropositionActivite"
}


export const ZOHO_URL = {
    base: "https://analytics.karaz.org/api/"
};
export const FACT_REFECTION_URL = {
    path: `${ZOHO_URL.base}Urbanisme-DML_RFC_RCP/FACT_REFECTION`
};
export const FACT_FACT_DOSSIER_URL = {
    path: `${ZOHO_URL.base}Urbanisme-PC-PH-Service%20Rendu/FACT_DOSSIER`
};
export const FACT_RECEPTION_URL = {
    path: `${ZOHO_URL.base}Urbanisme-DML_RFC_RCP/FACT_RECEPTION`
};
export const FACT_DEMOLITION_URL = {
    path: `${ZOHO_URL.base}Urbanisme-DML_RFC_RCP/FACT_DEMOLITION`
};

export const FACT_AUTORISATIONSD_URL = {
    path: `${ZOHO_URL.base}Economique/FACT_AUTORISATIONSD`
};
export const FACT_AUTORISATIONAN_URL = {
    path: `${ZOHO_URL.base}Economique/FACT_AUTORISATIONAN`
};
export const FACT_AUTORISATIONAFP_URL = {
    path: `${ZOHO_URL.base}Economique/FACT_AUTORISATIONAFP`
};
export const FACT_AUTORISATIONEC_URL = {
    path: `${ZOHO_URL.base}Economique/FACT_AUTORISATIONEC`
};
export const LIST_DOSSIERS_PC_URL = {
  path: `${ZOHO_URL.base}list des dossier pc (soumission)`,
};

export const SERACH_DOSSIER_CIN_URL = {
    path: `/front/karazal/kas/api-ps/folderCin/getAllFileCin`
};


export const CHECK_IF_DOSSIER_EXISTE = {
    path: `/front/karazal/kas/api-ps/folderCin/checkIfCinExiste`
};
export const getPCPhDeployer = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getListPCPH`,
};
export const getEcoDeployer = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getListEcoDeployer`,
};
export const getDimLieu = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getDimLieu`,
};
export const getListDIMREFREC = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getListDIMREFREC`,
};
export const getCommunListData = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getCommunListData`,
};
export const getPrefectureList = {
  path: `/front/karazal/kas/api-ps/couverturegeo/getPrefectureListData`,
};



