import { useState } from "react";
import { Skeleton } from 'antd';
import {useTranslation} from "react-i18next";



export const TagListUniqueSelection = (props) => {
    const [activeIndex, setActiveIndex] = useState(0); 
    const {t:tRA}=useTranslation("juridique");


    const normalTagStyle = {
        color: props.color,
        background: props.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        padding: '8px 20px',
        cursor: "pointer",
        border: "1px solid #6B7280",
        width: "fit-content",
        height: "fit-content",
        transition: "all 0.2s ease",
    };

    const activeTagStyle = {
        color: props.activeColor,
        borderRadius: "4px",
        padding: '8px 20px',
        border: "1px solid #6B7280",
        background: props.activeBackground,
        //transform: "scale(1.08)",
    };

    const containerStyle = {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexShrink: 0,
        overflowX: "auto",
        flexWrap: "nowrap",
    };

    const style = (index) => {
        return activeIndex === index
            ? { ...normalTagStyle, ...activeTagStyle }
            : normalTagStyle;
    };

    const onClick = (index) => {
        const newActiveIndex = activeIndex === index ? null : index; 
        setActiveIndex(newActiveIndex);
        props.onChange(newActiveIndex !== null ? props.tags[newActiveIndex] : null); 
    };

    return (
        <div style={containerStyle}>
            {props.tags.map((tag, index) => (
                <div key={index} style={style(index)} onClick={() => onClick(index)}>
                    {tRA(tag)}
                </div>
            ))}
        </div>
    );
};


export const TagListSkeleton = () => {
    const containerStyle = {
        display: "flex",
        height: "60px",
        alignItems: "center",
        width: "50%",
    };

    return (
        <div style={containerStyle}>
            {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton.Button key={index} active={true} size="large" />
            ))}
        </div>
    );
};


TagListUniqueSelection.defaultProps = {
    activeColor: "#FFFFFF",
    activeBackground: "#0369A1",
    color: "#6B7280",
    background: "#fff",
};
