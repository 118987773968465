import React, {useContext, useEffect,useState} from 'react'
import TitleParagraphButtons from '../pagescomponents/home/TitleParagraphButtons';
import Accompaniments from '../pagescomponents/landing-page/Accompaniments';
import Articles from '../pagescomponents/landing-page/Articles';
import Footer from '../pagescomponents/Footer';
import Header from '../pagescomponents/Header';
import Profils from '../pagescomponents/landing-page/Profils';

import FolderState from '../pages/folderState/FolderState';
import FolderCinTrack from '../pages/folderState/folderCinTrack.jsx';
import "./frontEnd.css";
import httpService from "../service/HttpService";
import ArticleListPage from "../pages/article/ArticleListPage";
import ArticleReadPage from '../pages/article/ArticleReadPage.jsx';
import RefActivitiesPage from "../pages/refactivities/RefActivitiesPage";
import AuthorizationListPage from "../pages/refactivities/RefActivitiesListPage";
import ArticlesPage from '../pages/article/ArticlesPages.jsx';

import {useQueries} from "react-query";
import {AppContext} from "../pagescomponents/AppProvider";
import NationalRankingPage from "../pages/national-ranking/NationalRankingPage";
import MethodologyDetail from "../pages/national-ranking/MethodologyDetail";
import PublicInquiry from "../pages/public-inquiry/PublicInquiry";
import useHandleTranslation from "../hooks/useHandleTranslation";
import SignConfirmation from "../pages/sign/SignConfirmation";
import RefJuridPage from "../pages/refJuri/refJurid.jsx";
import PageNotFound from "../pagescomponents/errorPages/PageNotFound";
import StatMapv2 from '../pages/geographiqueData/StatMapv2.jsx';
import PropositionActPage from '../pages/propositionAct/propositionAct.jsx';
import Coverturegeo from '../pages/Couverture-geographique/Coverturegeo.jsx';
import Geoportail from '../pages/geographiqueData/Geoportail.jsx';

export default function FrontEnd({ article,refjuri, propAct,articleId,refId,coverturegeo,suivDoss,signin, EmailVerification,completesignup,singup,dataProfils_large, dataSection1_large, dataAccompaniment_large, isInParametrage ,feat,refActivities,refList,nationalRanking,methodologyDetail,inquiry,signupConfirmation,geodata,geoportail,errorPage,votreDossier}) {
    const {showLoader,hideLoader}=useContext(AppContext);
    const [content, setContent] = useState([])
    const [contentItems, setContentItems] = useState({})
    const [screenState] = useState(window.innerWidth > 800 ? "large" : "tight")
    const {currLng,currDir}=useHandleTranslation();
    const axios=httpService.getAxiosClient();

    const [contents,setContents]=useState({header:null,footer:null,articles:null,profils:null,introduction:null,accompaniment:null})

    useEffect(() => {
        showLoader();
    }, []);

    const reactQueryFr=(lng,sizeScreen)=> {
        const queries = [
            {
                queryKey: ["header",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/global?content=header&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            },

            {
                queryKey: ["footer",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/global?content=footer&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            },

            {
                queryKey: ["introduction",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/landing-page?content=guichet_unique_rapid_access_parametrage&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            },

            {
                queryKey: ["profils",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/landing-page?content=profils_parametrage&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            },

            {
                queryKey: ["landing-page",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/landing-page?content=help_accompaniment_parametrage&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            },
            {
                queryKey: ["articles",lng],
                queryFn: () => axios.get(`/front/karazal/kas/api-el/content/landing-page?content=articles&lng=${lng}&size=${sizeScreen}`)
                    .then(response => response.data)
            }
        ]
        const results = useQueries(queries);
        return results;
    }

    const getContentOf =  (cible,lngCode) =>{
        if (isInParametrage && contentItems[cible]) {
            return contentItems[cible]
        }
        try {
            return content.filter((item) => (item.id === cible && item.code === lngCode))[0].source;
        }
        catch
            {
                return content[cible];
            }
    }


    const filterData=(type)=>resultFr.filter((item)=>item?.data?._id===`${type}_${screenState}`)[0]?.data?._source
    const resultFr=reactQueryFr(currLng,screenState);

    useEffect(()=> {
          if(resultFr.every((result)=>result.isFetched)){
              const newContent={
                  header:filterData("header"),
                  footer:filterData("footer"),
                  articles:filterData("articles"),
                  profils:filterData("profils_parametrage"),
                  introduction:filterData("guichet_unique_rapid_access_parametrage"),
                  accompaniment:filterData("help_accompaniment_parametrage")
              }
             setContents(newContent);
             setContent(resultFr.map(
                 element =>({
                     code: currLng,
                     id: element.data._id,
                     source: element.data._source
                 })
             ));
       } }, [resultFr.every((result)=>result.isFetched) && currLng]
    )

useEffect(()=> {
           if(resultFr.every((result)=>result.isFetched )) {
               hideLoader();
           }else{
               showLoader()
           };
        } , [resultFr.every((result)=>result.isFetched )]
    )
    useEffect(
        () => {
            setContentItems({
                ...contentItems,
                guichet_unique_rapid_access_parametrage_large: dataSection1_large ? dataSection1_large : contentItems?.guichet_unique_rapid_access_parametrage_large,
                help_accompaniment_parametrage_large: dataAccompaniment_large ? dataAccompaniment_large : contentItems?.help_accompaniment_parametrage_large,
                profils_parametrage_large: dataProfils_large ? dataProfils_large : contentItems?.profils_parametrage_large
            })

        }, [dataSection1_large, dataProfils_large, dataAccompaniment_large]
    )

    useEffect(() => {
        document.body.dir=currDir || 'ltr';
        document.body.lang=currLng ;
    }, []);
    let renderContent;
    if (article) {
        renderContent=<ArticlesPage ></ArticlesPage>
    }
    else if (articleId) {
        renderContent  = <ArticleReadPage />
    }
    else if(singup){
        renderContent=<Signup/>
    }else if(completesignup){
        renderContent=<CompleteSignUp/>
    }else if(EmailVerification){
        renderContent=<VerificationOfEmail/>
    }else if(signin){
        renderContent=<PopUpSignIn/>
    }
    else if(suivDoss){
        renderContent=<FolderState/>
    }
    else if(feat){
        renderContent=<ArticleListPage/>
    }
    else if(refActivities){
        renderContent=<RefActivitiesPage/>
    }
    else if(refList){
        renderContent=<AuthorizationListPage/>
    }
    else if (nationalRanking){
        renderContent=<NationalRankingPage/>
    }
    else if (methodologyDetail){
        renderContent=<MethodologyDetail/>
    }
    else if (refjuri){
        renderContent=<RefJuridPage/>
    }
    else if (propAct){
        renderContent=<PropositionActPage/>
    }
    else if (inquiry){
        renderContent=<PublicInquiry/>
    }
    else if (geodata){
        renderContent=<StatMapv2/>
    }
    else if (geoportail){
        renderContent=<Geoportail />
    }
    else if (coverturegeo){
        renderContent=<Coverturegeo/>
    }
    else if (signupConfirmation){
        renderContent=<SignConfirmation/>
    }else if (errorPage){
       renderContent=<PageNotFound/>
    }
    else if (votreDossier){
    renderContent=<FolderCinTrack />
 }

    return (
        content.length!==0 && (
            <div>
                <div className='cancel-mini-menu' onClick={() => { document.querySelector(".menu-burger-clicked")?.click(); document.querySelector(".cancel-mini-menu").classList.remove("cancel-actived"); document.querySelector(".body-content").classList.remove("body-content-cancel"); }}> </div>

                 <Header data={contents.header}  />
                <div className='body-content'>
                {renderContent  ? renderContent  : (
                    <>
                        <TitleParagraphButtons data={contents.introduction}  />
                        <Profils data={contents.profils} className={"profils-" + screenState} />
                        <Accompaniments data={contents.accompaniment} />
                        <Articles data={contents.articles}  />
                    </>
                )}
                </div>
                <Footer data={contents.footer} />
            </div>
        )
    );

}
