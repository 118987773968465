import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./jurid.module.css";
import GlobalSearch from "../../components/search/GlobalSearch";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import PdfViewerModal from "../../components/modal/PdfViewerModal";

import {
  ActivityCardSkeleton,
  RefJuridiqueCard,
} from "../../pagescomponents/respositorycard/RepositoryCard";
import { JURIDIQUE_CATHEGORIE_OBJECT } from "../../util/constants";
import { useNavigate } from "react-router-dom";
import {
  useGetAutoCompleteRefJur,
  useGetRefJuridiqueyCriteria,
} from "../../api/caller";
import {
  OPTIONS_MAPPING,
  REF_JURIDIQUE_TYPE_OPTIONS,
} from "../../util/constants";

import { Pagination, Tabs, Select, ConfigProvider } from "antd";
import { FilterButton } from "../../components/filters/FilterButton";
import classNames from "classnames";
import { EmptySearchData } from "../../components/emptyData/EmptyData";
import useHandleTranslation from "../../hooks/useHandleTranslation";
import { scrollToTopOnMount, toLogin } from "../../util/functionsUtil";
import {
  TagList,
  TagListSkeleton,
} from "../../components/cards/tagcard/TagList";
import { TagListUniqueSelection } from "../../components/cards/tagListUniqueSelection";
const ActivityContext = createContext({
  activityCard: <></>,
  activityCode: "",
  wrappedPage: false,
  setActivityCard: () => {},
  setActivityCode: () => {},
  setWrappedPage: () => {},
});
const RefJuridPage = () => {
  useEffect(() => {
    scrollToTopOnMount();
  }, []);
  const navigate = useNavigate();

  const [activityCard, setActivityCard] = useState(null);
  const [activityCode, setActivityCode] = useState(null);
  const [wrappedPage, setWrappedPage] = useState(false);
  const { t: tBC } = useTranslation("breadcrumb");
  const { t: tRA } = useTranslation("juridique");
  const { getDirX } = useHandleTranslation();
  const { currLng, isLngAr, isLngFr } = useHandleTranslation();
  const searchPageBreadcrumbParts = [
    { text: tBC("Accueil"), link: "/" },
    { text: tBC("Ressources"), link: "/" },
    { text: tBC("Référentiel juridique"), link: "/juridique" },
  ];
  const detailPageBreadcrumbPartsDetail = [...searchPageBreadcrumbParts];
  const breadcrumbParts = !wrappedPage
    ? searchPageBreadcrumbParts
    : detailPageBreadcrumbPartsDetail;

  const searchActivityClass = classNames({
    [styles.pageContent]: true,
    [styles.notDisplay]: wrappedPage,
    [styles.displayPageContent]: !wrappedPage,
  });

  return (
    <div id={"page"} style={{ minHeight: "100vh" }}>
      <div className="breadcrumb">
        <BreadCrumb parts={breadcrumbParts} />
      </div>
      <div className={styles.pageTitleContainer + " responsive-web"}>
        <div className={styles.pageTitle}>{tRA("page_title_3")}</div>
        <div className={styles.pageTitleDescription}>
          {tRA("title_description")}
        </div>
      </div>
      <div className={styles.pageTitleContainerMobile + " responsive-mobile"}>
        <div className={styles.pageTitleMobile}>
          {currLng == "fr" && (
            <div className="cursor-pointer pt-2">
              {wrappedPage && (
                <i
                  onClick={() => setWrappedPage(false)}
                  className={`fa-regular fa-arrow-${getDirX()}`}
                ></i>
              )}
              {!wrappedPage && (
                <i
                  onClick={() => navigate(-1)}
                  className={`fa-regular fa-arrow-${getDirX()}`}
                ></i>
              )}
            </div>
          )}
          <span className={styles.pageTitleMobileItem}>
            {tRA("page_title_3")}
          </span>
        </div>
        <div className={styles.pageTitleDescription}>
          {tRA("title_description")}
        </div>
      </div>

      <ActivityContext.Provider
        value={{
          activityCode,
          activityCard,
          wrappedPage,
          setActivityCard,
          setActivityCode,
          setWrappedPage,
        }}
      >
        <div className={styles.pageContentContainer}>
          {!wrappedPage && (
            <SearchActivityContent className={searchActivityClass} />
          )}
        </div>
      </ActivityContext.Provider>
    </div>
  );
};
const SearchActivityContent = (props) => {
  const [type, setType] = useState("");
  const { currLng, isLngAr, isLngFr } = useHandleTranslation();
  const [typeAutorization, setTypeAutorization] = useState({
    type: "tous",
  });
  const [selectedTag, setSelectedTag] = useState("Tous");
  const [activityCriteria, setActivityCriteria] = useState({
    noPage: 1,
    pageSize: 8,
    search: "",
    type: "",
    category: type,
    nature: "",
  });
  const [searchCriteria, setSearchCriteria] = useState("");
  const [prefix, setPrefix] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const { t: tRA } = useTranslation("juridique");
  const getActivityData = useGetRefJuridiqueyCriteria(
    activityCriteria,
    currLng
  );
  const getAutoCompleteActivity = useGetAutoCompleteRefJur(
    prefix,
    type,
    activityCriteria.type,
    currLng
  );
  const authorizationSelectOptions = JURIDIQUE_CATHEGORIE_OBJECT.data.map(
    (item) => ({ label: item.CODE, value: item.TYPE })
  );
  const [pdfViewerModalParams, setPdfModalParams] = useState({
    isOpen: false,
    url: "",
  });

  const previewDocument = (url) => {
    setPdfModalParams({ isOpen: true, url: url });
  };
  const handleClose = () => {
    setPdfModalParams({ ...pdfViewerModalParams, isOpen: false });
  };

  useEffect(() => {
    const element = document.getElementById("page");
    if (getActivityData.isFetching && element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [getActivityData.isFetching]);

  useEffect(() => {
    activityCriteria.category = type;
    setActivityCriteria({
      ...activityCriteria,
      noPage: 1,
      search: activityCriteria.search,
      category: type,
    });
  }, [type]);

  const onSearchClick = () => {
    activityCriteria.category = type;
    if (
      searchCriteria === activityCriteria.search &&
      activityCriteria.noPage === 1
    )
      getActivityData.refetch();
    else {
      if (searchCriteria.length !== 0)
        setActivityCriteria({
          ...activityCriteria,
          noPage: 1,
          search: searchCriteria,
          category: type,
        });
    }
  };

  const selectOptions = OPTIONS_MAPPING.data.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const onSearchChange = (string) => {
    setPrefix(string);
    setSearchCriteria(string);
    activityCriteria.category = type;
    if (string.length === 0)
      setActivityCriteria({ ...activityCriteria, search: "", category: type });
  };
  const onOptionChange = (value) => {
    setActivityCriteria({ ...activityCriteria, type: value });
  };

  const onSearchMenuClick = (string) => {
    setPrefix(string);
    setSearchCriteria(string);
    activityCriteria.category = type;
    if (string === activityCriteria.search) getActivityData.refetch();
    else {
      if (string.length !== 0)
        setActivityCriteria({
          ...activityCriteria,
          noPage: 1,
          search: string,
          category: type,
        });
    }
  };

  const onPaginationChange = (page, pageSize) => {
    setActivityCriteria({ ...activityCriteria, noPage: page });
  };
  const onChangeFilterButton = (isOpen) => {
    setShowFilter(isOpen);
  };

  const filterItemsContainerStyle = classNames({
    [styles.filterItemsContainer]: true,
    [styles.notShow]: !showFilter,
    [styles.filterItemsContainerAppear]: showFilter,
  });

  const translatedOptions = REF_JURIDIQUE_TYPE_OPTIONS.map((option) => ({
    ...option,
    label: tRA(option.label),
  }));

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    setType(value);
    setActivityCriteria((prev) => ({ ...prev, category: value, noPage: 1 }));
    getActivityData.refetch();
  };

  return (
    <div id="page">
      <div className={props.className}>
        <div className={styles.searchContainer}>
          <i className="fa-regular fa-magnifying-glass searchIcon" />
          <div className={`${styles.searchItemContainer}`}>
            <GlobalSearch
              loading={getActivityData.isFetching}
              onChange={onSearchChange}
              onClick={onSearchClick}
              menu={getAutoCompleteActivity.data?.data || []}
              onMenuClick={onSearchMenuClick}
              placeholder={tRA(`search_placeholder`)}
              allowFilter
              onOptionChange={setType}
            />
          </div>
          <div className={styles.filterContainer}>
            <FilterButton onChange={onChangeFilterButton} />
          </div>
        </div>
        {showFilter && (
          <div className={`${styles.SelectMobileVersion}`}>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    optionActiveBg: "#3b89b3",
                    optionActiveBg: "#3b89b3",
                    optionSelectedBg: "#3b89b3",
                    optionSelectedColor: "#0369A1",
                  },
                },
                token: {
                  colorText: "#0369A1",
                },
              }}
            >
              <Select
                className="w-full"
                defaultValue={tRA(selectedOption)}
                onChange={handleSelectChange}
                options={translatedOptions}
              />
            </ConfigProvider>
          </div>
        )}

        <div className={filterItemsContainerStyle} style={{ width: "100%" }}>
          <div className="py-0.5">
            <TagListUniqueSelection
              tags={authorizationSelectOptions.map((array) => array.label)}
              onChange={(tag) => {
                if ("Tous" == tag) tag = tag.toLowerCase();
                setSelectedTag(tag);
                setTypeAutorization({ type: tag });
                setActivityCriteria({ ...activityCriteria, type: tag });
              }}
            />
          </div>
        </div>

        <div className={styles.activityContentContainer}>
          {getActivityData.isFetched &&
            !getActivityData.isFetching &&
            getActivityData.data?.page &&
            getActivityData.data?.page?.map((item, index) => (
              <RefJuridiqueCard
                key={index}
                data={item}
                withAction
                onclick={previewDocument}
              />
            ))}
          {getActivityData.isFetching &&
            Array.from({ length: 8 }).map((element, index) => (
              <ActivityCardSkeleton key={index} withAction />
            ))}
        </div>

        {(getActivityData.isError ||
          getActivityData.data?.page?.length === 0) &&
          !getActivityData.isFetching && (
            <EmptySearchData search={searchCriteria} />
          )}
        {getActivityData.isFetched && getActivityData.data?.page && (
          <div className={styles.paginationContainer}>
            <Pagination
              current={activityCriteria.noPage}
              onChange={onPaginationChange}
              pageSize={activityCriteria.pageSize}
              total={getActivityData.data?.pagination?.totalPage}
              showSizeChanger={false}
            />
          </div>
        )}
      </div>
      <PdfViewerModal
        isOpen={pdfViewerModalParams.isOpen}
        url={pdfViewerModalParams.url}
        onClose={handleClose}
      />
    </div>
  );
};

export default RefJuridPage;
